import React from "react";
import Hero from "./Hero";
import EcoEq from "./EcoEq";
// import Counter from "./Counter";
import ProcesoCircular from "./ProcesoCircular";
import SomosTecnologia from "./SomosTecnologia";
import Sponsors from "./Sponsors";
import TrustInUs from "./TrustInUs";
import Partners from "./Partners";
import Autodiagnostico from "../Servicios/Autodiagnostico";
import Video from "./Video";
import { animateScroll as scroll } from "react-scroll";
import { useAppContext } from "../../context/AppContext";
import Leyrep from "../../components/Navigation/leyrep";

export default function Index() {
  const { setSection } = useAppContext();
  const [autodiagnostico, setAutodiagnostico] = React.useState(false);
  const [imagen, setImagen] = React.useState(true);

  
  React.useEffect(() => {
    scroll.scrollToTop();
    setSection("Home");
  });
  return (
    <div>
       {autodiagnostico && (
        <Autodiagnostico
          autodiagnostico={autodiagnostico}
          setAutodiagnostico={setAutodiagnostico}
        />
	)}
      <Hero setAutodiagnostico={setAutodiagnostico} />
      <ProcesoCircular />
      <EcoEq />
      <Video />
      <SomosTecnologia />
      <Sponsors />
      <Partners />
      <TrustInUs />
	  {imagen && (
		<Leyrep />
	  )}
	  
    </div>
  );
}
