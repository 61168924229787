import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";

import almacruz from "../../resources/trustinus/almacruz_2.png";
import bidasoa from "../../resources/trustinus/logo bidasoa .webp";
import casaco from "../../resources/trustinus/carrusel casaco-01.png";
import mrhoteles from "../../resources/trustinus/hotel_neruda.png";
import parque from "../../resources/trustinus/parque_del_recuerdo.png";
import play from "../../resources/trustinus/playm .png";
import proyecta from "../../resources/trustinus/partner proyecta-01.png";
import spaceworks from "../../resources/trustinus/spaceworks.png";
import torre from "../../resources/trustinus/torre.png";
import aquapur from "../../resources/trustinus/aquapur.png";
import dhl from "../../resources/trustinus/logo dhl.png";
import pata from "../../resources/trustinus/logo pata.png";
import spacio1 from "../../resources/trustinus/logo spacio1.png";
import pedidosya from "../../resources/trustinus/pedidosya logo.png";
import Slider from "../../components/ImageSlider";

const images = [
  almacruz,
  bidasoa,
  casaco,
  mrhoteles,
  parque,
  play,
  proyecta,
  spaceworks,
  torre,
  aquapur,
  dhl,
  pata,
  spacio1,
  pedidosya
];
const useStyles = makeStyles((theme) => ({
  sponsorContainer: {
    pointerEvents: "none",
  },
  title: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },
}));

export default function Sponsors() {
  const classes = useStyles();

  return (
    <Grid container className={classes.sponsorContainer}>
      <Grid item xs={12}>
        <Grid container alignContent={"center"} justify={"center"}>
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <br />
            <Typography
              variant={"h4"}
              component={"h2"}
              align={"center"}
              className={classes.title}
            >
              confían en <strong>nosotros</strong>
            </Typography>
            <br />
          </Grid>
        </Grid>
        <br />
        <Slider images={images} />
      </Grid>
    </Grid>
  );
}
