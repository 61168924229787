import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Servicios from "./pages/Servicios";
import Dashboard from "./pages/Dashboard";
import Historia from "./pages/Historia";
import Circulares from "./pages/Circulares";
import Noticias from "./pages/Noticias";
import Reciclamos from "./pages/Reciclamos";

export default function Routes() {
  const NotMatch = () => {
    return <Redirect to="/" />;
  };

  return (
    <Switch>
      <Route
        path="/"
        exact
        render={({ match }) => <Dashboard match={match} />}
      />
      <Route
        path="/historia"
        exact
        render={({ match }) => <Historia match={match} />}
      />
      <Route
        path="/servicios"
        exact
        render={({ match }) => <Servicios match={match} />}
      />
      <Route
        path="/circulares"
        exact
        render={({ match }) => <Circulares match={match} />}
      />
      <Route
        path="/noticias"
        exact
        render={({ match }) => <Noticias match={match} />}
      />
      <Route
        path="/reciclamos"
        exact
        render={({ match }) => <Reciclamos match={match} />}
      />
      <Route component={NotMatch} />
    </Switch>
  );
}
