import React, { useState } from "react";
import {
  Grid,
  makeStyles,
  IconButton,
  Drawer,
  Divider,
  ListItem,
  List,
  ListSubheader,
} from "@material-ui/core";

import { Link as LinkRouter } from "react-router-dom";
import { ChevronLeft, Menu as MenuIcon } from "@material-ui/icons";
import logo from "../../../resources/logo.png";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: "82px",
    padding: "16px",
    objectFit: "contain",
    marginTop: 6,
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
  },
  iconButtonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  drawerList: {
    width: "240px",
    paddingTop: "0px",
  },
  chevonItem: {
    display: "flex",
    height: "64px",
    justifyContent: "flex-end",
    paddingRight: "0px",
  },
  linkRouter: {
    textDecoration: "none",
    color: "#1E1D28",
    fontSize: 14,
  },
}));

export default function LandingPageAppBar() {
  const classes = useStyles();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const closeDrawer = () => setDrawerOpen(false);

  // ---------------- Render --------------
  return (
    <div style={{ width: "100%" }}>
      <Grid container>
        <Grid item xs={3} sm={2} className={classes.iconButtonContainer}>
          <IconButton
            aria-label="Open drawer"
            onClick={() => setDrawerOpen(true)}
            edge="start"
            color={"primary"}
          >
            <MenuIcon />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={6}
          sm={8}
          className={classes.logoContainer}
          align="center"
        >
          <LinkRouter className={classes.linkRouter} to="/">
            <img src={logo} alt="logo" className={classes.logo} />
          </LinkRouter>
        </Grid>
        <Grid item xs={3} sm={2} className={classes.logoContainer}></Grid>
        <Drawer open={drawerOpen} onClick={closeDrawer}>
          <List className={classes.drawerList}>
            <ListItem className={classes.chevonItem}>
              <IconButton onClick={closeDrawer}>
                <ChevronLeft />
              </IconButton>
            </ListItem>
            <Divider />
            <ListSubheader>{"Ir a"}</ListSubheader>
            <LinkRouter className={classes.linkRouter} to="/">
              <ListItem button onClick={closeDrawer}>
                {"Home"}
              </ListItem>
            </LinkRouter>
            <LinkRouter className={classes.linkRouter} to="/historia">
              <ListItem button onClick={closeDrawer}>
                {"Historia"}
              </ListItem>
            </LinkRouter>
            <LinkRouter className={classes.linkRouter} to="/servicios">
              <ListItem button onClick={closeDrawer}>
                {"Servicios"}
              </ListItem>
            </LinkRouter>
            <LinkRouter className={classes.linkRouter} to="/circulares">
              <ListItem button onClick={closeDrawer}>
                {"Circulares"}
              </ListItem>
            </LinkRouter>
            <LinkRouter className={classes.linkRouter} to="/reciclamos">
              <ListItem button onClick={closeDrawer}>
                {"Reciclamos"}
              </ListItem>
            </LinkRouter>
            <Divider />
            <ListSubheader>{"Plataforma"}</ListSubheader>
            <a
              href="https://platform.accioncircular.com"
              className={classes.linkRouter}
            >
              <ListItem button onClick={closeDrawer}>
                {"Ingresar"}
              </ListItem>
            </a>
          </List>
        </Drawer>
      </Grid>
    </div>
  );
}
