import React from "react";
import { Typography, Grid, makeStyles } from "@material-ui/core";
import ods_11 from "../../resources/icons/ods11.png";
import ods_12 from "../../resources/icons/ods12.png";
import ods_13 from "../../resources/icons/ods13.png";
import ods_img from "../../resources/icons/ODS_todos.png";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing(4)}px 0px ${theme.spacing(4)}px`,
    maxWidth: "100%",
  },

  itemTitle: {
    textTransform: "uppercase",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  title: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },

  items: {
    margin: "30px",
  },

  subtitles: {
    margin: `0px 0px ${theme.spacing(4)}px`,
  },
  img: {
    height: "128px",
  },
  imgContainer: {
    margin: `${theme.spacing(4)}px 0px ${theme.spacing(4)}px`,
    width: "100%",
    height: "128px",
    display: "flex",
    justifyContent: "center",
  },
}));

export default function ODS() {
  const classes = useStyles();
  return (
    <div>
      <Grid container spacing={4} className={classes.container}>
        <Grid item xs={12}>
          <Typography
            variant={"h4"}
            align="center"
            component={"h2"}
            className={classes.title}
          >
            NUESTROS <strong>ODS</strong>
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={4} className={classes.container}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item sm={3}>
              <img src={ods_11} style={{ width: "100%" }} alt="ods 11" />
            </Grid>
            <Grid item sm={9}>
              <Grid
                container
                direction="column"
                justify="space-between"
                alignItems="flex-start"
                spacing={3}
              >
                <Grid item>
                  <Typography
                    variant={"h5"}
                    align="left"
                    component={"h3"}
                    className={classes.title}
                  >
                    CIUDADES Y COMUNIDADES <strong>SOSTENIBLES</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant={"h6"}
                    component={"p"}
                    align="left"
                    className={classes.subtitle}
                  >
                    {
                      "•	De aquí a 2030, reducir el impacto ambiental negativo per cápita de las ciudades, incluso prestando especial atención a la calidad del aire y la gestión de los desechos municipales y de otro tipo."
                    }
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant={"h6"}
                    component={"p"}
                    align="left"
                    className={classes.subtitle}
                  >
                    {
                      "•	De aquí a 2030, proporcionar acceso universal a zonas verdes y espacios públicos seguros, inclusivos y accesibles, en particular para las mujeres y los niños, las personas de edad y las personas con discapacidad."
                    }
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant={"h6"}
                    component={"p"}
                    align="left"
                    className={classes.subtitle}
                  >
                    {
                      "•	Apoyar los vínculos económicos, sociales y ambientales positivos entre las zonas urbanas, periurbanas y rurales fortaleciendo la planificación del desarrollo nacional y regional."
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item sm={3}>
              <img src={ods_12} style={{ width: "100%" }} alt="ods 12" />
            </Grid>
            <Grid item sm={9}>
              <Grid
                container
                direction="column"
                justify="space-between"
                alignItems="flex-start"
                spacing={3}
              >
                <Grid item>
                  <Typography
                    variant={"h5"}
                    align="left"
                    component={"h3"}
                    className={classes.title}
                  >
                    PRODUCCIÓN Y CONSUMO <strong>RESPONSABLES</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant={"h6"}
                    component={"p"}
                    align="left"
                    className={classes.subtitle}
                  >
                    {
                      "•	De aquí a 2030, reducir considerablemente la generación de desechos mediante actividades de prevención, reducción, reciclado y reutilización"
                    }
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant={"h6"}
                    component={"p"}
                    align="left"
                    className={classes.subtitle}
                  >
                    {
                      "•	Alentar a las empresas, en especial las grandes empresas y las empresas transnacionales, a que adopten prácticas sostenibles e incorporen información sobre la sostenibilidad en su ciclo de presentación de informes."
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item sm={3}>
              <img src={ods_13} style={{ width: "100%" }} alt="ods 13" />
            </Grid>
            <Grid item sm={9}>
              <Grid
                container
                direction="column"
                justify="space-between"
                alignItems="flex-start"
                spacing={3}
              >
                <Grid item>
                  <Typography
                    variant={"h5"}
                    align="left"
                    component={"h3"}
                    className={classes.title}
                  >
                    ACCIÓN POR EL <strong>CLIMA</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant={"h6"}
                    component={"p"}
                    align="left"
                    className={classes.subtitle}
                  >
                    {
                      "•	Fortalecer la resiliencia y la capacidad de adaptación a los riesgos relacionados con el clima y los desastres naturales en todos los países."
                    }
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant={"h6"}
                    component={"p"}
                    align="left"
                    className={classes.subtitle}
                  >
                    {
                      "•	Mejorar la educación, la sensibilización y la capacidad humana e institucional respecto de la mitigación del cambio climático, la adaptación a él, la reducción de sus efectos y la alerta temprana."
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} md={8}>
              <img alt="ods" src={ods_img} style={{ width: "100%" }} />
            </Grid>
            <Grid item>
              <a href="https://www.un.org/sustainabledevelopment/es/objetivos-de-desarrollo-sostenible/">
                <Typography
                  variant={"h6"}
                  component={"p"}
                  align="center"
                  className={classes.title}
                >
                  {"AVERIGUA MÁS SOBRE LOS ODS AQUÍ"}
                </Typography>
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
