import React from "react";
import { AppBar, Toolbar, Grid, makeStyles, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
import Drawer from "./Drawer";
import ThisMenu from "./Menu";
import Coti from "./Coti";
import Contacto from "../../../pages/Servicios/contacto"; 
import logo from "../../../resources/logo.png";
import bpendiente from "../../../resources/logo_b_pendiente.png";
// import latino from "../../../resources/latinoamerica.png";


const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    height: "96px",
    [theme.breakpoints.down("sm")]: {
      height: "80px",
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: "white",
    boxShadow: "none",
    height: "65px",
  },
  logo: {
    height: "90px",
    padding: "16px",
    objectFit: "contain",
    margin: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginTop: 6,
      height: "64px",
      padding: theme.spacing(1),
    },
  },
  logoBPendiente: {
    height: "104px",
    padding: "16px",
    objectFit: "contain",
    margin: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginTop: 6,
      height: "64px",
      padding: theme.spacing(1),
    },
  },
}));

export default function LandingPageAppBar() {
  const classes = useStyles();
  const [coti, setCoti] = React.useState(false);
  const [contacto, setContacto] = React.useState(false);
  return (
    
    <AppBar position="fixed" className={classes.appBar}>

      {coti && (
      <Coti
      coti={coti}
      setCoti={setCoti}
      />
    )}
	
	{contacto && (
        <Contacto
          contacto={contacto}
          setContacto={setContacto}
        />
		)}
      <Toolbar disableGutters className={classes.toolbar}>
        <Hidden smDown>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item>
                  <Link to="/">
                    <img
                      src={logo}
                      alt="Logo Acción Circular"
                      className={classes.logo}
                    />
                  </Link>
                </Grid>
                <Grid item>
                  <img
                    src={bpendiente}
                    alt="Logo b pendiente"
                    className={classes.logoBPendiente}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
			{/*<ThisMenu setCoti={setCoti} setContacto={setContacto}/>*/}
              <ThisMenu setCoti={setCoti}  />
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Drawer />
        </Hidden>
      </Toolbar>
    </AppBar>
	
  );
}
