import React, { useState } from "react";
import axios from "axios";
import {
  Grid,
  makeStyles,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { fade } from "@material-ui/core/styles/colorManipulator";
import ReactGA from "react-ga";
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';

import FooterPhoto from "../../resources/backgrounds/Dashboard/contacto.jpg";

const clipPathLarge =
  "polygon(0.0% 4.999999999999999%,2.1739130434782608% 4.4776216725425755%,4.3478260869565215% 3.960960835199195%,6.521739130434782% 3.4556723995053384%,8.695652173913043% 2.9672868047690093%,10.869565217391305% 2.5011494867844872%,13.043478260869565% 2.0623623714305395%,15.217391304347824% 1.6557280335123215%,17.391304347826086% 1.285697132034762%,19.565217391304348% 0.9563196972343485%,21.73913043478261% 0.6712008025382771%,23.913043478260867% 0.43346110662641046%,26.08695652173913% 0.2457026974676496%,28.26086956521739% 0.10998061217162902%,30.43478260869565% 0.02778034437418586%,32.608695652173914% 1.5853408269483893e-06%,34.78260869565217% 0.02694837674366124%,36.95652173913043% 0.10832578289077244%,39.130434782608695% 0.24324311883085814%,41.30434782608695% 0.4302236990011671%,43.47826086956522% 0.6672209997186729%,45.65217391304348% 0.9516410586141805%,47.826086956521735% 1.280370865845023%,50.0% 1.6498124363412963%,52.17391304347826% 2.055922190161026%,54.347826086956516% 2.494255209931811%,56.52173913043478% 2.9600138909762213%,58.69565217391304% 3.448100451639794%,60.8695652173913% 3.9531727290901357%,63.04347826086956% 4.469702649895764%,65.21739130434783% 4.992036735417568%,67.39130434782608% 5.514457979774517%,69.56521739130434% 6.0312484231222285%,71.73913043478261% 6.5367517353727855%,73.91304347826086% 7.02543512536987%,76.08695652173913% 7.491949897916259%,78.26086956521739% 7.931189995850138%,80.43478260869564% 8.33834788642058%,82.6086956521739% 8.708967180279423%,84.78260869565217% 9.03899140716875%,86.95652173913044% 9.3248084144485%,89.13043478260869% 9.563289902517308%,91.30434782608695% 9.751825664406882%,93.47826086956522% 9.88835215479367%,95.65217391304347% 9.971375075736777%,97.82608695652173% 9.999985731938589%,100% 9.973870976518025% , 100% 100%, 0% 100%);";
const clipPathShort =
  "polygon(0.0% 2.4999999999999996%,2.1739130434782608% 2.2388108362712877%,4.3478260869565215% 1.9804804175995976%,6.521739130434782% 1.7278361997526692%,8.695652173913043%  1.4836434023845047%,10.869565217391305% 1.2505747433922436%,13.043478260869565% 1.0311811857152697%,15.217391304347824% 0.8278640167561607%,17.391304347826086% 0.642848566017381%,19.565217391304348% 0.47815984861717425%,21.73913043478261% 0.33560040126913854%,23.913043478260867% 0.21673055331320523%,26.08695652173913% 0.1228513487338248%,28.26086956521739% 0.05499030608581451%,30.43478260869565% 0.01389017218709293%,32.608695652173914% 7.926704134741946e-07%,34.78260869565217% 0.01347418837183062%,36.95652173913043% 0.05416289144538622%,39.130434782608695% 0.12162155941542907%,41.30434782608695% 0.21511184950058354%,43.47826086956522% 0.33361049985933644%,45.65217391304348% 0.47582052930709023%,47.826086956521735% 0.6401854329225115%,50.0% 0.8249062181706481%,52.17391304347826% 1.027961095080513%,54.347826086956516% 1.2471276049659055%,56.52173913043478% 1.4800069454881106%,58.69565217391304% 1.724050225819897%,60.8695652173913% 1.9765863645450679%,63.04347826086956% 2.234851324947882%,65.21739130434783% 2.496018367708784%,67.39130434782608% 2.7572289898872584%,69.56521739130434% 3.0156242115611143%,71.73913043478261% 3.2683758676863928%,73.91304347826086% 3.512717562684935%,76.08695652173913% 3.7459749489581293%,78.26086956521739% 3.965594997925069%,80.43478260869564% 4.16917394321029%,82.6086956521739% 4.354483590139711%,84.78260869565217% 4.519495703584375%,86.95652173913044% 4.66240420722425%,89.13043478260869% 4.781644951258654%,91.30434782608695% 4.875912832203441%,93.47826086956522% 4.944176077396835%,95.65217391304347% 4.9856875378683885%,97.82608695652173% 4.999992865969294%,100% 4.986935488259013% , 100% 100%, 0% 100%);";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "#eb01a5",
    backgroundSize: "cover",
    backgroundImage: "url(" + FooterPhoto + ")",
    margin: `${theme.spacing(4)}px 0px 0px`,
    padding: "160px 0px 192px",
    maxWidth: "100%",
    clipPath: clipPathLarge,
    [theme.breakpoints.down("sm")]: {
      clipPath: clipPathShort,
      backgroundImage: "url(" + FooterPhoto + ")",
    },
  },
  contactTitle: {
    margin: `0px 0px ${theme.spacing(2)}px`,
    textTransform: "uppercase",
    color: "#fff",
  },
  buttonDiv: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  navlink: {
    textDecoration: "none",
    color: "#000",
    align: "center",
  },
  icon: {
    margin: `${theme.spacing(1)}px 0px ${theme.spacing(1)}px`,
    width: "70%",
    height: "70px",
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      opacity: 0.5,
    }
  },
  items: {
    margin: "15px",
  },
}));

const useInputStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #e2e2e1",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "#fcfcfb",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
}));

export default function Contact() {
  const classes = useStyles();
  const inputStyles = useInputStyles();
  const [contactForm, setContactForm] = useState({
    name: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleClick = () => {
    ReactGA.event({
      category: "Landing",
      action: "Envío email contacto",
    });
    if (
      !contactForm.name ||
      !contactForm.lastName ||
      !contactForm.email ||
      !contactForm.message
    ) {
      enqueueSnackbar(
        "Debes completar todos los campos antes de enviar el email",
        {
          variant: "error",
        }
      );
    } else {
      axios
        .post("/landing/contact", {
          email: contactForm.email,
          name: contactForm.name,
          lastName: contactForm.lastName,
          phone: contactForm.phone,
          message: contactForm.message,
        })
        .then((res) => {
          setContactForm({
            name: "",
            lastName: "",
            email: "",
            phone: "",
            message: "",
          });
          enqueueSnackbar("¡Mensaje enviado correctamente!", {
            variant: "success",
            autoHideDuration: "6000",
          });
        })
        .catch((err) => {
          enqueueSnackbar("No se pudo enviar el email!", {
            variant: "error",
            autoHideDuration: "6000",
          });
        });
    }
  };

  const setByName = (event) =>
    setContactForm({ ...contactForm, [event.target.name]: event.target.value });

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignContent="center"
      className={classes.container}
      spacing={4}
    >
      <Grid item xs={12} sm={10} md={6} lg={4}>
        <Typography
          variant={"h6"}
          align="center"
          className={classes.contactTitle}
        >
          {"¡Seamos Circulares!"}
        </Typography>

        <TextField
          id="contact_name"
          label="Nombre"
          name="name"
          value={contactForm.name}
          onChange={setByName}
          margin="dense"
          variant="filled"
          fullWidth
          required
          InputProps={{ classes: inputStyles, disableUnderline: true }}
        />

        <TextField
          id="contact_lastname"
          label="Empresa"
          name="lastName"
          value={contactForm.lastName}
          onChange={setByName}
          margin="dense"
          variant="filled"
          fullWidth
          required
          InputProps={{ classes: inputStyles, disableUnderline: true }}
        />

        <TextField
          id="contact_email"
          label="Email"
          name="email"
          type="email"
          value={contactForm.email}
          onChange={setByName}
          margin="dense"
          variant="filled"
          fullWidth
          required
          InputProps={{ classes: inputStyles, disableUnderline: true }}
        />

        <TextField
          id="contact_phone"
          label="Teléfono (Opcional)"
          name="phone"
          type="phone"
          value={contactForm.phone}
          onChange={setByName}
          margin="dense"
          variant="filled"
          fullWidth
          InputProps={{ classes: inputStyles, disableUnderline: true }}
        />

        <TextField
          id="contact_message"
          label="Mensaje"
          name="message"
          value={contactForm.message}
          onChange={setByName}
          margin="dense"
          variant="filled"
          multiline
          fullWidth
          required
          InputProps={{ classes: inputStyles, disableUnderline: true }}
          rows="3"
        />

        <div style={{ width: "100%", display: "flex", justifyContent: "center" }} >
          <Button variant="contained" onClick={handleClick} style={{backgroundColor:"#0077B5", color:"#fff"}}>
            {"Enviar"}
          </Button>
        </div>
      </Grid>
      <Grid item xs={12} sm={10} md={6} lg={4}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12} sm={4} lg={3} className={classes.items}>
            <a href="https://www.linkedin.com/company/accion-circular/" className={classes.navlink}>
              <LinkedInIcon className={classes.icon} style={{ color: "#fff" }} />
            </a>
          </Grid>
          <Grid item xs={12} sm={4} lg={3} className={classes.items} >
            <a href="https://www.instagram.com/accion.circular/" className={classes.navlink}>
              <InstagramIcon className={classes.icon} style={{ color: "#fff" }} />
            </a>
          </Grid>
          <Grid item xs={12} sm={4} lg={3} className={classes.items} >
            <a href="https://web.facebook.com/Acci%C3%B3n-Circular-367959440520955?_rdc=1&_rdr" className={classes.navlink}>
              <FacebookIcon className={classes.icon} style={{ color: "#fff" }} />
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
