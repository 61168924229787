import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

import image_1 from "../../../resources/orgullo/1.JPG";
import image_2 from "../../../resources/orgullo/2.JPG";
import image_3 from "../../../resources/orgullo/3.JPG";
import image_6 from "../../../resources/orgullo/6.JPG";
import image_5 from "../../../resources/orgullo/5.jpg";

import image_4 from "../../../resources/orgullo/4.JPG";
import image_7 from "../../../resources/orgullo/7.JPG";
import image_8 from "../../../resources/orgullo/8.jpeg";
import image_9 from "../../../resources/orgullo/9.jpeg";
import image_10 from "../../../resources/orgullo/10.png";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: `${theme.spacing(4)}px`,
    marginBottom: `${theme.spacing(4)}px`,
    background: "#fff",
  },
  textContainer: {
    padding: 20,
  },
  title: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },
  imgContainer: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(2)}px`,
    width: "100%",
  },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

const orgullos = [
  {
    title: "Plan piloto Barrio Manuel Montt",
    date: "2018 agosto – diciembre",
    text: `Bajo el nombre de Mercado Circular, contribuimos al primer piloto de reciclaje en colaboración con locatarios del Barrio Manuel Montt, dueños de pubs, restaurants y negocios del sector, que buscaban dar un destino más sustentable a los residuos generados a diario debido a la naturaleza de sus negocios. Por otra parte, estos pagaban por sobregeneración de residuos en sus patentes municipales, por lo cual nos coordinamos con el área de Aseo y Ornato, y fiscalización, de manera que estuviesen en conocimiento de aquellos locatarios que estaban participando del piloto. 
    En el desarrollo del piloto, y en alianza con Cristalerías Toro, se lograron reciclar más de 20 toneladas de vidrio que tuvieron una disposición responsable y participaron 13 locales.
    `,
    image: image_1,
    links: [],
  },
  {
    title:
      "Seleccionados para participar del programa The S-Factory G9 en Start-Up Chile",
    date: "Febrero 2019",
    text: `Postulamos con un prototipo de plataforma que buscaba conectar a todos los actores de la cadena del reciclaje, y que además utilizara la internet of things (IOT), para automatizar y volver más eficiente el proceso de reciclaje y gestión de residuos en las empresas. Así, entre 153 postulantes de 13 países, Acción Circular (en este tiempo “Mercado Circular”), fue seleccionado para participar en un programa de 4 meses de pre-aceleración.
      `,
    image: image_2,
    links: [
      {
        pre: "Link a la nota",
        link:
          "https://www.startupchile.org/es/siguiendo-la-tendencia-chilenas-lideran-las-startups-aceptadas-en-start-up-chile/",
      },
    ],
  },
  {
    title: "# 25 en el Ranking Innovation Latam Awards 2019",
    date: "julio 2019",
    text: `El objetivo de este ranking es exponer a las startups más innovadoras de Latinoamérica. Soluciones de más de 16 países son evaluadas por un jurado internacional a partir del aporte de sus productos al mercado y el carácter innovador. Entre las startups evaluadas se encuentran aquellas de los rubros Adtechs, Agrotech, Constru/Propotech, Fintechs, Healthtechs, Retailtechs, Smart Cities and Social Impact.
      Así fue como, entre más de 1.700 postulantes, Accion Circular se posiciono en el lugar #25 del ranking del 2019, y #2 emprendimiento de chileno más innovador.
      `,
    image: image_3,
    links: [],
  },
  {
    title:
      "Ganadores de ambas extensiones de The S-Factory G9 en Start-Up Chile",
    date: "Agosto 2019",
    text: `En agosto de 2019 participamos en la competencia de Pitch como finalistas del programa The S-Factory Generación 9 en la aceleradora Start-Up Chile. Gracias al gran desempeño mostrado por Acción Circular durante los 4 meses de pre- aceleración, obtuvimos el reconocimiento de la extensión del programa, lo cual significaba un aporte de $5 millones de pesos, más $3 millones adicionales por contener resultados de triple impacto (económico, social y ambiental). Durante 2019, la participación de Acción Circular en TSF G9, logró recaudar $18 millones en fondos públicos, los cuales tuvieron como resultado el primer Producto mínimo viable de la plataforma y el prototipo del dispositivo ultrasónico, además de concretar las primeras ventas.
      `,
    image: image_4,
    links: [
      {
        pre: "Video del evento",
        link: "https://www.youtube.com/watch?v=HDoRyXvaXvU",
      },
      {
        pre: "Video de las 10 finalistas",
        link: "https://www.youtube.com/watch?v=0EZOYuEx47g",
      },
    ],
  },
  {
    title: "Finalistas desafíos Entel Go! y Pitch en FIIS 2019",
    date: "Noviembre 2019",
    text: `EntelGO es un desafío que busca emprendimientos que usen la tecnología digital para el bien y que contribuyan al bienestar social o medioambiental del planeta. Estos emprendimientos debiesen impactar directamente en alguno de los ODS 11 de Ciudades y Comunidades Sostenibles, ODS 12 de Producción y Consumo Responsables y ODS 13 de Acción por el Clima.
      Acción Circular fue seleccionado como parte de los finalistas de la convocatoria, donde además tuvieron la oportunidad de participar en FIIS 2019 en una competencia de Pitch junto a otras 8 startups, realizar un Bootcamp con Agora Partnerships para levantamiento de inversión y presentar frente a la Asociación Chilena de Fondos de Inversión (ACAFI).
      `,
    image: image_5,
    links: [],
  },
  {
    title:
      "Ganadores de Convocatoria Investing the Future 2019, de Chile Global Ventures",
    date: "Diciembre 2019",
    text: `ChileGlobal Ventures, el área de capital de riesgo de Fundación Chile, en alianza con el Consejo Asesor Empresarial de la APEC (ABAC), invitaron a las mejores startups de todo el mundo a sumarse a Inventing The Future, convocatoria oficial de la APEC, que financiaría y permitiría el desarrollo de negocios innovadores enfocados en los dos grandes desafíos que enfrenta la sociedad actual: Transformación Digital y Economía Circular.
      En esta oportunidad Acción Circular fue seleccionado como parte de las 4 startups seleccionadas para ingresar al portafolio de Chile Global Ventures, dado el impacto en desafíos de Internet of things (IOT) y un impacto en la logística e infraestructura para promover el reciclaje.
      `,
    image: image_6,
    links: [
      {
        pre: "Enlace de la convocatoria",
        link:
          "https://www.facebook.com/ChileGlobalVentures/videos/491725451388524/",
      },
    ],
  },
  {
    title: "Participación en la primera versión de la feria AMUSA",
    date: "Enero 2020",
    text: `El evento se desarrolló el 21 y 22 de enero de 2020 en la Municipalidad de Vitacura.Esta feria contó con más de 25 proyectos sustentables, donde exhibimos nuestras  propuestas en un espacio abierto y gratuito.
      La Feria de Emprendimiento e Innovación Sustentable de la Asociación de Municipalidades para la Sustentabilidad Ambiental (AMUSA), tuvo por objetivo visibilizar a los emprendedores medioambientales que puedan ofrecer una solución a nivel comunal.
      En esta oportunidad pudimos mostrar nuestro dispositivo ultrasónico leyendo en tiempo real el estado de llenado de un contenedor de reciclaje, visibilizando el aporte que puede significar el uso de la tecnología para mantener limpias nuestras ciudades a partir del reciclaje inteligente.
      `,
    image: image_7,
    links: [
      {
        pre: "Link a la nota",
        link:
          "https://www.paiscircular.cl/ciudad/primera-feria-de-emprendimiento-sustentable-de-amusa-reune-a-25-proyectos-medioambientales-en-vitacura/",
      },
    ],
  },
  {
    title: "Mención honrosa en Innovación Social por Sé Santiago Smart City",
    date: "Febrero 2020",
    text: `Diecisiete nominados en cinco categorías, cinco menciones honrosas y cinco ganadores, tuvo la ceremonia de los Smart City Awards 2019, evento organizado por el Programa Estratégico Regional de Corfo Metropolitano, Santiago Ciudad Inteligente (Sé Santiago).
      Las cinco categorías fueron: Comunicaciones, Liderazgo, Internacionalización, Innovación Social y Emprendimiento. Los miembros de la gobernanza del programa estratégico fueron los encargados de elegir a los ganadores y entregar las menciones honrosas.
      `,
    image: image_8,
    links: [
      {
        pre: "Link de la noticia",
        link:
          "http://www.sesantiago.cl/el-emprendimento-y-lo-innovacion-fueron-premiados-en-los-smart-city-awards/",
      },
    ],
  },
  {
    title: "Bootcamp de Agora Partnerships",
    date: "Febrero 2020",
    text: `Participamos 2 días en un Bootcamp de “Pitch para la inversión” junto a otras 12 startups de triple impacto, como parte del premio por ser finalistas del Desafío Entel Go.
      Agora empodera emprendedores con propósito mediante conocimientos, redes y capital. Somos una comunidad de emprendedores, inversionistas, consultores y aliados que toman acción para fortalecer el emprendimiento a lo largo de América.
      `,
    image: image_9,
    links: [],
  },
  {
    title: "500 mejores iniciativas en los  Premios Latinoamérica Verde 2020",
    date: "Mayo 2020",
    text: `El evento internacional, conocido como los «Premios Oscar del Medio Ambiente», busca exhibir, conectar, capacitar y premiar a los 500 mejores proyectos sociales y ambientales que tengan un impacto en la región, o que sean desarrollados por Latinos dentro o fuera de la región.
      Pueden concursar ONG’s, PYMES, Empresas Públicas y Privadas, Instituciones y Público. En general pueden participar del evento registrando sus proyectos en 10 categorías de los premios, mismas que están alineadas a los Objetivos de Desarrollo Sostenibles de la ONU: Agua, Bosque y Flora, Ciudades Sostenibles, Desarrollo Humano, Energía, Fauna, Finanzas Sostenible, Manejo de Residuos Sólidos, Océanos, Producción y Consumo Responsable.
      En una convocatoria que reunió más de 2.500 postulaciones, Accion Circular  quedo dentro de los 500 mejores proyectos en el lugar #348 del general y #74 en la categoría de Manejo de Residuos Sólidos.
      `,
    image: image_10,
    links: [
      {
        pre: "Link a Premios Latinoamérica verde",
        link: "https://www.premioslatinoamericaverde.com/",
      },
    ],
  },
];
export default function Equipo() {
  const classes = useStyles();

  return (
    <Grid container justify="center" spacing={6} className={classes.container}>
      <Grid item xs={12}>
        <Typography
          variant={"h4"}
          component={"h2"}
          align={"center"}
          className={classes.title}
        >
          Nuestro <strong>Orgullo</strong>
        </Typography>
      </Grid>
      <Grid item xs={12} md={10} align="center">
        <hr />
        {orgullos.map((data, i) => (
          <div>
            <Item key={i} data={data} />
            <hr />
          </div>
        ))}
      </Grid>
    </Grid>
  );
}

const Item = ({ data }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={9}>
        <Typography
          variant={"h5"}
          component={"h3"}
          align={"center"}
          className={classes.title}
        >
          {data.title}
        </Typography>
        <Typography
          variant={"h6"}
          component={"h6"}
          align={"center"}
          className={classes.title}
        >
          {data.date}
        </Typography>
        {data.text.split("\n").map((paragraph, i) => (
          <div key={"paragraph_nuestro_orgullo" + i} style={{ margin: 20 }}>
            <Typography component={"p"} align={"justify"}>
              {paragraph}
            </Typography>
          </div>
        ))}
        {data.links.map((link, i) => (
          <div>
            <a key={"link_nuestro_orgullo" + i} href={link.link}>
              {link.pre}
            </a>
            <br />
          </div>
        ))}
      </Grid>
      <Grid item xs={12} md={3}>
        <img src={data.image} alt="imagen" className={classes.imgContainer} />
      </Grid>
    </Grid>
  );
};
