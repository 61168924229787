import React from "react";

import Header from "./Header";
import Suscripciones from "./Suscripciones";
import Esquema from "./Esquema";
import Plataforma from "./Plataforma";
import Autodiagnostico from "./Autodiagnostico";
import Quienes from "./Quienes";
import DiagnosticoResiduos from "./DiagnosticoResiduos";
import Talleres from "./Talleres";
import TallerOnline from "./TallerOnline";
//import LeyREP from "./LeyREP";
import Leyrep from "../../components/Navigation/leyrep";

import { Element } from "react-scroll";
import { animateScroll as scroll } from "react-scroll";
import { useAppContext } from "../../context/AppContext";
import ReactGA from "react-ga";

export default function Index() {
  const { setSection } = useAppContext();
  const [imagen, setImagen] = React.useState(true);

  React.useEffect(() => {
    scroll.scrollToTop();
    setSection("Servicios");
    ReactGA.pageview("/servicios");
  });
  const [autodiagnostico, setAutodiagnostico] = React.useState(false);
  return (
    <div>
      <Header />
      {autodiagnostico && (
        <Autodiagnostico
          autodiagnostico={autodiagnostico}
          setAutodiagnostico={setAutodiagnostico}
        />
      )}
		{imagen && (
		<Leyrep />
	  )}
      <Element name="reciclaje">
        <Quienes setAutodiagnostico={setAutodiagnostico} />
        <Esquema />
        <Suscripciones setAutodiagnostico={setAutodiagnostico} />
      </Element>
      <Element name="informatica">
        <Plataforma />
      </Element>
      <Element name="consultoria">
        <DiagnosticoResiduos />
        <Talleres />
        <TallerOnline />
       
      </Element>
    </div>
  );
}
