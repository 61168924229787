import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing(4)}px 0px ${theme.spacing(4)}px`,
    maxWidth: "100%",
  },
  title: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },
  subtitles: {
    margin: `0px 0px ${theme.spacing(4)}px`,
  },
  // imgContainer: {
  //   margin: `${theme.spacing(2)}px 0px ${theme.spacing(2)}px`,
  //   width: "60%",
  // },
}));

export default function LocalInfo({ info }) {
  const classes = useStyles();

  return (
    <Grid>
      <Grid>
        <Typography className={classes.title}>
          {info.name}
        </Typography>
        <Typography className={classes.subtitles}>
          {info.direction}
        </Typography>
      </Grid>
      {/* <img width={240} src={info.photo} className={classes.imgContainer}/> */}
    </Grid>
  );
}