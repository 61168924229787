import React from "react";
import { Grid, withWidth, Zoom } from "@material-ui/core";

function Slider(props) {
  const { width, images } = props;
  const [grids, setGrids] = React.useState(0);
  const [inZoom, setInZoom] = React.useState(true);
  const [memory, setMemory] = React.useState([]);
  const [imagesShow, setImagesShow] = React.useState({
    image_1: 0,
    image_2: 0,
    image_3: 0,
    image_4: 0,
  });
  React.useEffect(() => {
    let l = 0;
    if (width === "xs") {
      l = 1;
    }
    if (width === "sm") {
      l = 2;
    }
    if (width === "md") {
      l = 3;
    }
    if (width === "lg") {
      l = 4;
    }
    setGrids(l);
    setImagesShow({
      image_1: images[0],
      image_2: images[1],
      image_3: images[2],
      image_4: images[3],
    });
  }, [width, images]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setInZoom(false);
      setTimeout(() => {
        let values = [];
        let newValues = [];
        let memoryTemp = [];

        //Crear arrglo de posiciones en imagenes
        for (let i = 0; i < images.length; i++) {
          values.push(i);
        }

        // Eliminar de los valores posibles los que ya pasaron
        for (let i = 0; i < memory.length; i++) {
          let index = values.indexOf(memory[i]);
          if (index !== -1) {
            values.splice(index, 1);
          }
        }

        let i = 0;

        // Si los que quedan son menos que la cantidad de imagenes que se renderean, agregar
        if (values.length < grids) {
          while (values.length < grids) {
            values.push(i);
            i++;
          }
        }

        i = 0;

        while (i < grids) {
          i++;
          // De los que quedan elegir cualquier imagen al azar
          let current = values.splice(
            Math.floor(Math.random() * values.length),
            1
          )[0];
          newValues["image_" + i] = images[current];
          // Agregarla a la memoria
          memoryTemp.push(current);
        }

        // concatenar la memoria nueva con la memoria antigua
        memoryTemp = memoryTemp.concat(memory);
        // eliminar elemntos repetidos
        memoryTemp = new Set(memoryTemp);
        memoryTemp = [...memoryTemp];

        // Si son del mismo tamaño reiniciar memoria
        if (memory.length === images.length) {
          memoryTemp = [];
        }

        setMemory(memoryTemp);
        setImagesShow({ ...newValues });
        setInZoom(true);
      }, 300);
    }, 4000 + Math.random() * 2000);
    return () => clearInterval(interval);
  }, [imagesShow, images, width]);

  const Frame = ({ image, t }) => {
    return (
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        lg={3}
        align="center"
        style={{ minHeight: 100 }}
      >
        <Zoom timeout={300 + 1500 * t} in={inZoom}>
          <img
            src={image}
            style={{ maxHeight: 90, maxWidth: "100%" }}
            alt={"image_"}
          />
        </Zoom>
      </Grid>
    );
  };

  return (
    <Grid container spacing={4} justify="center" style={{ marginBottom: 20 }}>
      <Grid item xs={6} sm={6}>
        <Grid container spacing={6} alignItems="center">
          {grids > 0 && <Frame image={imagesShow.image_1} t={Math.random()} />}
          {grids > 1 && <Frame image={imagesShow.image_2} t={Math.random()} />}
          {grids > 2 && <Frame image={imagesShow.image_3} t={Math.random()} />}
          {grids > 3 && <Frame image={imagesShow.image_4} t={Math.random()} />}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withWidth()(Slider);
