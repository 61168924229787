import React from "react";
import { render } from "react-dom";
import {
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import img1 from "../../resources/icons/pop-up2.png";
import Contacto from "../../pages/Servicios/contacto"; 
const footerStyle = {

  fontSize: "20px",
  color: "white",
  textAlign: "center",
  position: "fixed",
  bottom: "0",
  width:"100%",
  zIndex:'1'
};



const closeImg = {
	cursor:'pointer', 
	float:'center', 
	width: '20px',
	position: 'fixed',
	borderRadius: '50%',
	bottom: "250px",
	marginLeft:'490px',

	};
	
const ima={
	width:'1000px',
	height:'250px',
};

export default function ventana (){
	const [contacto, setContacto] = React.useState(false);
	const [imagen, setImagen] = React.useState(true);
	const imageClick = () => {
		setImagen(false);
	}
	return(
			
				imagen && (
				  <div id="rp" style={footerStyle}>
					{
						contacto && (
					  <Contacto
					  contacto={contacto}
					  setContacto={setContacto}
					  />
					)
					}
					<Button style={closeImg} class="close" onClick={() => imageClick()}>
						<span>x</span>
					</Button>
					<div  >
					
					<img src={img1} style={ima} onClick={() => setContacto(true)} />
					</div>
				  </div>
				)
			
		);
}

