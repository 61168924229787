import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

import Pauli from "../../../resources/equipo/pauli.jpg";
import Caro from "../../../resources/equipo/caro.jpg";
import Olivia from "../../../resources/equipo/olivia.jpg";
import Nati from "../../../resources/equipo/nati.jpg";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing(4)}px 0px ${theme.spacing(4)}px`,
    maxWidth: "100%",
    background: "#fff",
  },
  title: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },
  subtitles: {
    margin: `0px 0px ${theme.spacing(4)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },
  body: {
    color: "#003398",
  },
  itemTitle: {
    textTransform: "uppercase",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    color: "#003398",
  },
  items: {
    margin: "40px",
  },
  img: {
    height: "128px",
  },
  imgContainer: {
    margin: `${theme.spacing(4)}px 0px ${theme.spacing(4)}px`,
    width: "100%",
    height: "128px",
    display: "flex",
    justifyContent: "center",
  },
  large: {
    width: "100%",
  },
  circle: {
    borderRadius: "50%",
    width: "100%",
  },
}));

export default function Equipo() {
  const classes = useStyles();

  return (
    <Grid container justify="center" spacing={6} className={classes.container}>
      <Grid item xs={12}>
        <Typography
          variant={"h4"}
          component={"h2"}
          align={"center"}
          className={classes.title}
        >
          Nuestro <strong>Equipo</strong>
        </Typography>
      </Grid>

      <Grid
        item
        xs={10}
        sm={10}
        md={10}
        lg={12}
        container
        spacing={4}
        justify="center"
      >
        <Grid item xs={9} sm={4} md={3} lg={2} align="center">
          <img alt="pauli" src={Pauli} className={classes.circle} />
          <br />
          <Typography
            variant={"h6"}
            align="center"
            component={"h3"}
            className={classes.subtitle}
          >
            Paulina Cataldo Ide
          </Typography>
          <Typography
            variant={"p"}
            align="center"
            component={"p"}
            className={classes.subtitle}
          >
            <strong>Chief Executive Officer</strong>
          </Typography>
        </Grid>
        <Grid item xs={9} sm={4} md={3} lg={2} align="center">
          <img alt="feña" src={Caro} className={classes.circle} />
          <br />
          <Typography
            variant={"h6"}
            align="center"
            component={"h3"}
            className={classes.subtitle}
          >
            Carolina López Cruz
          </Typography>
          <Typography
            variant={"p"}
            align="center"
            component={"p"}
            className={classes.subtitle}
          >
            <strong>Chief Marketing Officer</strong>
          </Typography>
        </Grid>
        <Grid item xs={9} sm={4} md={3} lg={2} align="center">
          <img alt="caro" src={Nati} className={classes.circle} />
          <br />
          <Typography
            variant={"h6"}
            align="center"
            component={"h3"}
            className={classes.subtitle}
          >
            Natalia Vergara Gavilán
          </Typography>
          <Typography
            variant={"p"}
            align="center"
            component={"p"}
            className={classes.subtitle}
          >
            <strong>Full Stack Developer</strong>
          </Typography>
        </Grid>
        <Grid item xs={9} sm={4} md={3} lg={2} align="center">
          <img alt="caro" src={Olivia} className={classes.circle} />
          <br />
          <Typography
            variant={"h6"}
            align="center"
            component={"h3"}
            className={classes.subtitle}
          >
            Olivia Muñiz Braun
          </Typography>
          <Typography
            variant={"p"}
            align="center"
            component={"p"}
            className={classes.subtitle}
          >
            <strong>Key Account Manager</strong>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
