import React from "react";
import Questions from "./Questions.json";
import {
  Grid,
  Button,
  ButtonGroup,
  Typography,
  makeStyles,
  Dialog,
  LinearProgress,
  TextField,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CloseIcon from "@material-ui/icons/Close";
import Axios from "axios";
import ReactGA from "react-ga";
import validator from "email-validator";

import Whale from "../../../resources/icons/ballena .png";
import Bird from "../../../resources/icons/bird.png";
import Elephant from "../../../resources/icons/elefante.png";

import {
  CheckboxComponent,
  InputComponent,
  RadioButtonComponent,
  TextFieldComponent,
} from "./Components";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },
  error: {
    color: "red",
  },
  img: {
    height: 90,
    filter: "invert(100%);",
    opacity: 0.6,
    margin: 10,
  },
}));

export default function Index({ autodiagnostico, setAutodiagnostico }) {
  const classes = useStyles();
  const [answers, setAnswers] = React.useState({});
  const [textOthr, setTextOthr] = React.useState({});
  const [showText, setShowText] = React.useState(false);
  const [currentQuestion, setCurrentQuestion] = React.useState(null);
  const [plan, setPlan] = React.useState({
    plan: "",
    image: "",
  });
  const [state, setState] = React.useState({
    ready: false,
    history: [],
    error: false,
    end: false,
    required: true,
  });

  // EFECTO DE INICIO
  React.useEffect(() => {
    // SI SE CARGARON LAS PREGUNTAS
    if (!currentQuestion) {
      // console.log(Questions.config.INIT_Q);
      setCurrentQuestion({
        ...Questions.questions[Questions.config.INIT_Q],
        name: Questions.config.INIT_Q
      });
      setState({ ...state, ready: true, history: [Questions.config.INIT_Q] });
      // console.log(currentQuestion);
    }
    ReactGA.event({
      category: "Landing",
      action: "Diagnóstico empezado",
    });
  }, [currentQuestion]);

  React.useEffect(() => {
    if (state.error) {
      setTimeout(() => {
        setState({ ...state, error: false });
      }, [3000]);
    }
  }, [state.error]);

  const handleNext = () => {
    setShowText(false);
    console.log(currentQuestion.name);
    console.log(currentQuestion);
    let last = currentQuestion.name;
    let newQ = "";

    if(last === "Q_EMAIL"){
      // SI NO SE CONTESTO LA PREGUNTA
      if (!answers["Q_NAME"]) {
        setState({ ...state, error: true });
        return;
      }
      if (!answers["Q_COMMUNE"]) {
        setState({ ...state, error: true });
        return;
      }
    }

    // if (
    //   currentQuestion.validate === "EMAIL" &&
    //   !validator.validate(answers[last])
    // ) {
    //   setState({ ...state, error: true });
    //   return;
    // }
    

    // SI NO SE CONTESTO LA PREGUNTA
    if (currentQuestion.required && !answers[last]) {
      setState({ ...state, error: true });
      return;
    }

    // SI SE ACABO EL FORMULARIO
    if (currentQuestion.nextQuestion === "END") {
      setState({ ...state, end: true });
      createPayloadEmail();
      return;
    }

    if (currentQuestion.conditional) {
      newQ =
        currentQuestion.answers[answers[currentQuestion.name]].nextQuestion;
      setCurrentQuestion({
        ...Questions.questions[newQ],
        name: newQ,
      });
    } else {
      newQ = currentQuestion.nextQuestion;
      setCurrentQuestion({
        ...Questions.questions[newQ],
        name: newQ,
      });
    }
    if (!answers[newQ]) {
      setAnswers({ ...answers, [newQ]: "" });
    }
    setState({
      ...state,
      history: [...state.history, last],
    });
  };

  const createPayloadEmail = () => {
    let payload = [];
    Object.keys(answers).map((key, _i) => {
      return payload.push({
        question: Questions.questions[key].question,
        answer: answers[key],
        textOthr: textOthr[key],
        key,
      });
    });
    let total = 0;
    payload.forEach((q) => {
      let obj = Questions.questions[q.key];
      // Si el tipo es radio button implica que tiene puntaje
      if (obj.type === "radioButton") {
        total += obj.answers[q.answer].points * obj.ponderation;
      }
    });
    let auxPlan = "";
    if (total < 2.0) {
      auxPlan = "Bird";
      setPlan({
        image: Bird,
        plan: auxPlan,
      });
    } else if (total >= 2.0 && total < 2.5) {
      auxPlan = "Elephant";
      setPlan({
        image: Elephant,
        plan: auxPlan,
      });
    } else if (total >= 2.5) {
      auxPlan = "Whale";
      setPlan({
        image: Whale,
        plan: auxPlan,
      });
    }
    payload.push({
      question: "Puntaje obtenido: ",
      answer: total,
    });

    payload.push({
      question: "Plan ofrecido: ",
      answer: auxPlan,
    });

    ReactGA.event({
      category: "Landing",
      action: "Diagnóstico completado",
    });

    Axios.post("/landing/diagnostic", {payload, plan: auxPlan} );
  };

  const handleBack = () => {
    if (state.history.length > 1) {
      let aux = state.history;
      let name = aux.pop();
      setState({
        ...state,
        history: aux,
      });
      setCurrentQuestion({
        ...Questions.questions[name],
        name,
      });
    }
  };

  return (
    <Dialog
      open={autodiagnostico}
      onClose={() => setAutodiagnostico(false)}
      fullWidth
      maxWidth="sm"
      style={{
        zIndex: 10000,
      }}
    >
      <Grid container justify="center" style={{ padding: 10 }}>
        <Grid item>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12} align="center">
              <Typography
                variant={"h4"}
                component={"h3"}
                align={"center"}
                className={classes.title}
              >
                AUTO<strong>DIAGNÓSTICO</strong>
              </Typography>
              <br></br>
              <LinearProgress
                variant="determinate"
                value={
                  state.end
                    ? 100
                    : Math.round((state.history.length / 13) * 100)
                }
              />
            </Grid>
            <Grid item xs={12} align="center">
              {!state.end ? (
                <Grid item xs={11} sm={10} md={8} align="center">
                  <Typography
                    variant={"h6"}
                    component={"h5"}
                    align={"justify"}
                    className={classes.title}
                  >
                    {state.history.length}){" "}
                    {state.ready && currentQuestion.question}

                  </Typography>
                  <br></br>
                  {state.error && (
                    <Typography
                      variant={"p"}
                      component={"p"}
                      align={"center"}
                      className={classes.error}
                    >
                      Esta pregunta es obligatoria
                    </Typography>
                  )}
                  {state.ready && currentQuestion.type === "textFieldMuch" && (
                    <>
                    {/* {console.log(currentQuestion.name)} */}
                    <Typography
                      variant={"h6"}
                      component={"h5"}
                      align={"justify"}
                      className={classes.title}
                      >
                      {state.ready && currentQuestion.question1}

                    </Typography>
                    <TextFieldComponent
                      setAnswers={setAnswers}
                      answers={answers}
                      valueName={currentQuestion.name}
                    />                     
                    </> 
                  )}

                  {state.ready && currentQuestion.type === "textFieldMuch" && currentQuestion.question2 != "" && (                    
                    <>
                    {/* {console.log(currentQuestion.name2)} */}
                      <Typography
                      variant={"h6"}
                      component={"h5"}
                      align={"justify"}
                      className={classes.title}
                      >
                      {state.ready && currentQuestion.question2}

                      </Typography>

                      <TextFieldComponent
                        setAnswers={setAnswers}
                        answers={answers}
                        valueName={currentQuestion.name2}
                      />

                      </>      
                    )}

                    {state.ready && currentQuestion.type === "textFieldMuch" && currentQuestion.question3 != "" && (                    
                    <>
                    {/* {console.log(currentQuestion.name3)} */}
                      <Typography
                      variant={"h6"}
                      component={"h5"}
                      align={"justify"}
                      className={classes.title}
                      >
                      {state.ready && currentQuestion.question3}

                      </Typography>

                      <TextFieldComponent
                        setAnswers={setAnswers}
                        answers={answers}
                        valueName={currentQuestion.name3}
                      />

                      </>      
                    )} 
                  
                  {state.ready && currentQuestion.type === "textField" && (
                    <TextFieldComponent
                      setAnswers={setAnswers}
                      answers={answers}
                      valueName={currentQuestion.name}
                    />        
                  )}

                  {state.ready && currentQuestion.type === "checkBox" && (
                    <CheckboxComponent
                      setAnswers={setAnswers}
                      answers={answers}
                      valueName={currentQuestion.name}
                      options={currentQuestion.answers}
					  showText={showText}
					  setShowText = {setShowText}
                      conditional={currentQuestion.conditional}
					  textOthr={textOthr}
                      setTextOthr={setTextOthr}
                    />
                  )}
                  {state.ready && currentQuestion.type === "radioButton" && (
                    <RadioButtonComponent
                      setAnswers={setAnswers}
                      answers={answers}
                      valueName={currentQuestion.name}
                      options={currentQuestion.answers}
                      showText={showText}
                      setShowText = {setShowText}
                      conditional={currentQuestion.conditional}
                      textOthr={textOthr}
                      setTextOthr={setTextOthr}
                    />
                  )}
                  {state.ready && currentQuestion.type === "input" && (
                    <InputComponent
                      setAnswers={setAnswers}
                      answers={answers}
                      valueName={currentQuestion.name}
                    />
                  )}                                    
                </Grid>
              ) : (
                  <Grid item xs={12} align="center">
                    <Typography
                      variant={"h5"}
                      component={"h4"}
                      align={"center"}
                      className={classes.title}
                    >
                      ¡FELICIDADES, HAS TERMINADO TU AUTO
                    <strong>DIAGNÓSTICO!</strong>
                    </Typography>
                    <Typography variant={"h6"} component={"h5"} align={"center"}>
                      El resultado más de acorde para ti es el plan:{" "}
                      <strong style={{ color: "#003398" }}>{plan.plan}</strong>
                    </Typography>
                    <img
                      src={plan.image}
                      className={classes.img}
                      alt="imagen del plan"
                    />
                    <Typography component={"p"} align={"center"}>
                      El equipo se pondrá en contacto contigo a la brevedad para
                      validar tu suscripción y resolver tus dudas
                  </Typography>
                    {/* <br />
                      <Typography
                        variant={"h6"}
                        component={"h5"}
                        align={"center"}
                      >
                        Gracias por preferir Acción Circular
                      </Typography> */}
                  </Grid>
                )}
            </Grid>
           
            <Grid item xs={12} sm={10} align="center">
              <br />
              {!state.end ? (
                <ButtonGroup variant="contained" size="large" fullWidth>
                  <Button
                    onClick={() => handleBack()}
                    startIcon={<ArrowBackIosIcon />}
                  >
                    Atrás
                  </Button>
                  <Button
                    endIcon={<ArrowForwardIosIcon />}
                    onClick={() => handleNext()}
                    color="primary"
                  >
                    {state.required ? "Siguiente" : "Siguiente / omitir"}
                  </Button>
                </ButtonGroup>
              ) : (
                  <ButtonGroup variant="contained" size="large" fullWidth>
                    <Button
                      color="primary"
                      onClick={() => setAutodiagnostico(false)}
                      startIcon={<CloseIcon />}
                    >
                      Salir
                  </Button>
                  </ButtonGroup>
                )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}
