import React from "react";
import Header from "./Header";
import Historia from "./Historia";
import Valores from "./Valores";
import ODS from "./ODS";
import { animateScroll as scroll } from "react-scroll";
import { Element } from "react-scroll";
import ReactGA from "react-ga";

import { useAppContext } from "../../context/AppContext";

export default function Index() {
  const { setSection } = useAppContext();

  React.useEffect(() => {
    scroll.scrollToTop();
    setSection("Historia");
    ReactGA.pageview("/historia");
  });

  return (
    <div>
      <Header />
      <Element name="historia">
        <Historia />
      </Element>
      <Element name="valores">
        <Valores />
      </Element>
      <Element name="ods">
        <ODS />
      </Element>
    </div>
  );
}
