import React from "react";
import { Grid, makeStyles, Typography, Button } from "@material-ui/core";
import image_1 from "../../resources/images/image_1.jpeg";

const textos = {
  historia: String(
    "Acción Circular nace en 2018, frente a una necesidad cada vez más urgente de poder mejorar la forma en que las personas y empresas se hacen cargo de la basura que generan en su día a día.\n Así es como un grupo de jóvenes en un emprendimiento de sostenibilidad, y un inversionista que creía que el reciclaje era el futuro, unieron propósitos para comenzar a idear una solución que permitiera contribuir al cumplimiento de los desafíos que dejaba la Ley REP y el camino necesario para convertirnos en un país desarrollado, sobretodo en materia de sustentabilidad.\n Así es como tras múltiples postulaciones fallidas y tropiezos buscando partners, en 2019 aterrizamos en Start-Up Chile, al programa The S-Factory, un programa que apoyaba a emprendedoras que lideraban iniciativas de base tecnológica. Así ingresamos con nuestro prototipo de plataforma, pero con un sueño mucho más ambicioso:\n Automatizar el proceso completo de recolección de reciclaje utilizando la Internet de las Cosas. Crear sensores que permitieran alentar el nivel de llenado de puntos limpios, evitar rebalses y coordinar automáticamente los retiros con la amplia red de recolectores bases presentes en las ciudades, asegurando la trazabilidad de los materiales y contribuyendo a disminuir los costos de operación en el reciclaje, a través de algoritmos de eficiencia en la programación de rutas y retiros, reportabilidad y uso inteligente de los datos para tomar decisiones. \n Si bien ha sido un camino lleno de dificultades e iteraciones, de búsqueda, tolerancia a la frustración y sobretodo, reinvención. Acción Circular es lo que es, gracias a que estamos enamorados de nuestro propósito y no de la solución que ofrecemos al mercado. Contamos con un equipo con garra y comprometido, y múltiples partners y personas que han creído en este proyecto, las cuales nos han ayudado a salir adelante."
  ),
  historia2: "esta historia tiene saltos de linea \n uno",
};
const useStyles = makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing(4)}px 0px ${theme.spacing(4)}px`,
    maxWidth: "100%",
  },

  itemTitle: {
    textTransform: "uppercase",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  title: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },

  items: {
    margin: "30px",
  },

  subtitles: {
    margin: `0px 0px ${theme.spacing(4)}px`,
  },
  img: {
    height: "128px",
  },
  imgContainer: {
    margin: `${theme.spacing(4)}px 0px ${theme.spacing(4)}px`,
    width: "100%",
    height: "128px",
    display: "flex",
    justifyContent: "center",
  },
}));

export default function History() {
  const classes = useStyles();
  const [readMore, setReadMore] = React.useState(false);

  return (
    <Grid container spacing={4} justify="center">
      <Grid item xs={12}>
        <Typography
          variant={"h4"}
          component={"h2"}
          align={"center"}
          className={classes.title}
        >
          NUESTRA <strong>HISTORIA</strong>
        </Typography>
      </Grid>
      <Grid item xs={11} sm={11} md={8}>
        {(readMore ? textos.historia : textos.historia.substr(0, 640) + "...")
          .split("\n")
          .map((paragraph, i) => (
            <div key={"paragraph_" + i} style={{ margin: 20 }}>
              <Typography variant={"h6"} component={"p"} align="justify">
                {paragraph}
                {i + 1 ===
                  (readMore
                    ? textos.historia
                    : textos.historia.substr(0, 870) + "..."
                  ).split("\n").length && (
                  <Button
                    color="primary"
                    onClick={() => setReadMore(!readMore)}
                  >
                    {readMore ? "Minimizar" : "Seguir leyendo"}
                  </Button>
                )}
              </Typography>
              <br />
            </div>
          ))}
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <img
          src={image_1}
          style={{ width: "100%" }}
          alt="Sensor Acción Circular"
        />
      </Grid>
      <Grid item xs={11} align="center">
        <Typography
          variant={"h4"}
          component={"h2"}
          align={"center"}
          className={classes.title}
        >
          NUESTRA <strong>VISIÓN</strong>
        </Typography>

        <Typography
          variant={"h6"}
          component={"p"}
          align="center"
          className={classes.title}
        >
          {
            "“Contribuir a aumentar las tasas de reciclaje de países en desarrollo en un 20% de aquí al 2030”"
          }
        </Typography>
      </Grid>
    </Grid>
  );
}
