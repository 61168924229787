import React from "react";

import oxxo from "../../../resources/reciclamos/logo_oxxo.png"

const pinStyle = {
  cursor: "pointer",
  fill: "#d00",
  stroke: "none",
};

export default function LocalPin({ size, onClick }) {

  return (
    <img
      src={oxxo}
      style={{
        ...pinStyle,
        // transform: `translate(${-size / 2}px,${-size}px)`,
        width: "9%",
      }}
      onClick={onClick}
      alt="oxxo_logo"
    />
  );
}
