import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import LocalIcon from "../../resources/icons/local-02.png";
import CamionIcon from "../../resources/icons/camion-02.png";
import SensorIconRed from "../../resources/icons/sensor-02.png";
import ValorizerIcon from "../../resources/icons/valorizador-02.png";
// import CamionIcon from '../../resources/landing_resources/icons/camion-01.png';
// import FeatureTraceability from '../../resources/landing_resources/icons/punto de venta-01.png';
// import FeaturesAcknowledgment from '../../resources/landing_resources/icons/valorizador-01.png';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing(4)}px 0px ${theme.spacing(4)}px`,
    maxWidth: "100%",
  },

  itemTitle: {
    textTransform: "uppercase",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    // color: "#003398",
  },
  title: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },

  items: {
    margin: "30px",
  },

  subtitles: {
    margin: `0px 0px ${theme.spacing(4)}px`,
  },
  img: {
    height: "128px",
  },
  imgContainer: {
    margin: `${theme.spacing(4)}px 0px ${theme.spacing(4)}px`,
    width: "100%",
    height: "128px",
    display: "flex",
    justifyContent: "center",
  },
}));

export default function ProcesoCircular() {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justify="center"
      spacing={2}
      className={classes.container}
      id={"proceso"}
    >
      <Grid item xs={12}>
        <Grid container justify="center">
          <Grid item xs={12} sm={10} md={10} lg={8}>
            <Typography
              variant={"h4"}
              component={"h2"}
              align={"center"}
              className={classes.title}
            >
              Somos <strong>CIRCULARES</strong>
            </Typography>
            <Typography
              variant={"h6"}
              component={"p"}
              align="center"
              className={classes.subtitles}
            >
              Acción Circular <strong>conecta</strong> y promueve la
              colaboración entre actores claves de la industria y la sociedad en
              relación al reciclaje, para construir ciudades más inteligentes,
              responsables e <strong>inclusivas</strong>, con soluciones{" "}
              <strong>
                tecnológicas aplicadas desafíos de economía circular.
              </strong>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12} sm={3} lg={2} className={classes.items}>
            <div className={classes.imgContainer}>
              <img
                src={LocalIcon}
                alt="Punto de Venta"
                className={classes.img}
              />
            </div>
            <Typography
              className={classes.itemTitle}
              variant={"h6"}
              component={"h5"}
              align="center"
            >
              {"PUNTOS DE RETIRO"}
            </Typography>
            <Typography align="center" component={"p"}>
              De manera manual por la plataforma o a través de sensores
              ultrasónicos de llenado, instalados en contenedores de reciclaje,
              notifican su necesidad de retiro de residuos reciclables.
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3} lg={2} className={classes.items}>
            <div className={classes.imgContainer}>
              <img src={SensorIconRed} alt="IOT" className={classes.img} />
            </div>
            <Typography
              className={classes.itemTitle}
              variant={"h6"}
              component={"h5"}
              align="center"
            >
              {"Sensores"}
            </Typography>
            <Typography align="center" component={"p"}>
              Los sensores de ultrasonido envían información a la plataforma,
              indicando el estado del contenedor.
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3} lg={2} className={classes.items}>
            <div className={classes.imgContainer}>
              <img
                src={CamionIcon}
                alt="Transportistas"
                className={classes.img}
              />
            </div>
            <Typography
              className={classes.itemTitle}
              variant={"h6"}
              component={"h5"}
              align="center"
            >
              {"TRANSPORTISTAS"}
            </Typography>
            <Typography align="center" component={"p"}>
              Mediante una subasta los transportistas reciben ofertas de
              retiros, en donde la plataforma organiza una ruta eficiente para
              minimizar el impacto ambiental del transporte.
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3} lg={2} className={classes.items}>
            <div className={classes.imgContainer}>
              <img
                src={ValorizerIcon}
                alt="Reciclaje"
                className={classes.img}
              />
            </div>
            <Typography
              className={classes.itemTitle}
              variant={"h6"}
              component={"h5"}
              align="center"
            >
              {"VALORIZADORES"}
            </Typography>

            <Typography align="center" component={"p"}>
              Obtienen un flujo constante de materiales, según las
              características de su operación, para continuar con la producción
              de nuevos productos a partir del reciclaje de materiales
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
