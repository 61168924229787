/* eslint-disable max-len */
import React from "react";
import {
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import NetflixSlider from "../../../components/NetflixSlider/NetflixSlider";
import img1 from "../../../resources/icons/flecha.png";
import img2 from "../../../resources/icons/objetivot.png";
import img3 from "../../../resources/icons/sostenible.png";

const movies = [
  {
    id: 1,
    image: "/images/taller_1.jpeg",
    imageBg: "/images/taller_1.jpeg",
    title: "TALLER",
    description: "",
  },
  {
    id: 2,
    image: "/images/taller_2.jpeg",
    imageBg: "/images/taller_2.jpeg",
    title: "TALLER",
    description: "",
  },
  {
    id: 3,
    image: "/images/taller_3.jpeg",
    imageBg: "/images/taller_3.jpeg",
    title: "TALLER",
    description: "",
  },
  {
    id: 4,
    image: "/images/taller_4.jpeg",
    imageBg: "/images/taller_4.jpeg",
    title: "TALLER",
    description: "",
  },
  {
    id: 5,
    image: "/images/taller_5.jpeg",
    imageBg: "/images/taller_5.jpeg",
    title: "TALLER",
    description: "",
  },
  {
    id: 6,
    image: "/images/taller_6.jpeg",
    imageBg: "/images/taller_6.jpeg",
    title: "TALLER",
    description: "",
  },
  {
    id: 7,
    image: "/images/taller_7.jpeg",
    imageBg: "/images/taller_7.jpeg",
    title: "TALLER",
    description: "",
  },
  {
    id: 8,
    image: "/images/taller_8.jpeg",
    imageBg: "/images/taller_8.jpeg",
    title: "TALLER",
    description: "",
  },
  {
    id: 9,
    image: "/images/taller_9.jpeg",
    imageBg: "/images/taller_9.jpeg",
    title: "TALLER",
    description: "",
  },
  {
    id: 10,
    image: "/images/taller_10.jpeg",
    imageBg: "/images/taller_10.jpeg",
    title: "TALLER",
    description: "",
  },
  {
    id: 11,
    image: "/images/taller_11.jpeg",
    imageBg: "/images/taller_11.jpeg",
    title: "TALLER",
    description: "",
  },
];

const useStyles = makeStyles((theme) => ({
  title: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },
  subtitles: {
    margin: `0px 0px ${theme.spacing(4)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },
  body: {
    // color: "#003398",
  },
  itemTitle: {
    textTransform: "uppercase",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    color: "#003398",
  },
  items: {
    marginLeft: "40px",
    marginRight: "40px",
  },
  img: {
    height: 90,
    opacity: 0.6,
  },
  imgArrow: {
    height: 50,
    marginTop: 30,
    opacity: 0.6,
  },
  imgContainer: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(2)}px`,
    width: "100%",
    height: 90,
    display: "flex",
    justifyContent: "center",
  },
}));

export default function Talleres() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid container direction="row" justify="center" spacing={4}>
      <Grid item xs={12}>
        <Grid container justify="center">
          <Grid item xs={11} sm={10} md={8} lg={8}>
            <Typography
              variant={"h4"}
              component={"h2"}
              align={"center"}
              className={classes.title}
            >
              TALLERES DE <strong>IMPACTO</strong>
            </Typography>
            <br />
            <Typography
              variant={"h6"}
              component={"p"}
              align="justify"
              className={classes.subtitle}
            >
              Sensibilizar sobre las problemáticas ambientales asociadas a la
              producción de basura, además de otorgar herramientas y
              conocimientos para aprender a reconocer los distintos tipos de
              materiales
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={10}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={10} sm={3}>
            <div className={classes.imgContainer}>
              <img
                src={img2}
                alt="objetivo"
                className={classes.img}
              />
            </div>
            <Typography
              className={classes.itemTitle}
              variant={"h5"}
              component={"h4"}
              align="center"
            >
              {"OBJETIVO"}
            </Typography>
            <Typography
              align="center"
              className={classes.body}
              variant={"h6"}
              component={"p"}
            >
              Instruir a los trabajadores sobre el reconocimiento, segregación y
              disposición de residuos aptos para reciclar. Así se logra
              comprometer a los colaboradores con el reciclaje en la empresa.
            </Typography>
          </Grid>
          <Grid item xs={12} md={1}>
            <div className={classes.imgContainer}>
              <img
                src={img1}
                alt="Colaboración"
                className={classes.imgArrow}
              />
            </div>
          </Grid>
          <Grid item xs={10} sm={3}>
            <div className={classes.imgContainer}>
              <img
                src={
                  "https://www.flaticon.es/premium-icon/icons/svg/934/934057.svg"
                }
                alt="dinámica"
                className={classes.img}
              />
            </div>
            <Typography
              align="center"
              className={classes.itemTitle}
              variant={"h5"}
              component={"h4"}
            >
              {"DINÁMICA"}
            </Typography>
            <Typography
              align="center"
              className={classes.body}
              variant={"h6"}
              component={"p"}
            >
              Durante el taller se facilitan de herramientas técnicas y visuales
              para instaurar nuevas prácticas de reciclaje en los colaboradores,
              generando un ambiente apto para el aprendizaje.
            </Typography>
          </Grid>
          <Grid item xs={12} md={1}>
            <div className={classes.imgContainer}>
              <img
                src={img1}
                alt="Resultado"
                className={classes.imgArrow}
              />
            </div>
          </Grid>
          <Grid item xs={10} sm={3}>
            <div className={classes.imgContainer}>
              <img
                src={img3}
                alt="control"
                className={classes.img}
              />
            </div>
            <Typography
              align="center"
              className={classes.itemTitle}
              variant={"h5"}
              component={"h4"}
            >
              {"RESULTADOS"}
            </Typography>

            <Typography
              align="center"
              className={classes.body}
              variant={"h6"}
              component={"p"}
            >
              Empoderar a los colaboradores para que incorporen las mejores
              prácticas de reciclaje y sean facilitadores del proceso, logrando
              una empresa más sostenible y más verde.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {!isMobile && (
        <Grid item xs={12}>
          <br />
          <Grid container justify="center">
            <Grid item xs={12} sm={10} md={8} lg={6}>
              <Typography
                variant={"h4"}
                component={"h3"}
                align={"center"}
                className={classes.title}
              >
                Nuestros <strong>talleres</strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <NetflixSlider>
                {movies.map((movie) => (
                  <NetflixSlider.Item
                    movie={movie}
                    key={"foto_netflix_talleres_" + movie.id}
                  />
                ))}
              </NetflixSlider>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
