import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing(4)}px 0px ${theme.spacing(4)}px`,
    maxWidth: "100%",
  },
  title: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },
  subtitles: {
    margin: `0px 0px ${theme.spacing(4)}px`,
  },
  imgContainer: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(2)}px`,
    width: "60%",
  },
}));

export default function Locales({ locales }) {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={4}
      className={classes.container}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid container spacing={2} direction="column" justify="center" alignItems="center">
        {
          locales.map((local, idx) => {
            return (
              <Grid key={idx} item align="center">
                <Grid
                  container
                  spacing={4}
                >
                  <Grid item md={6} >
                    <Typography
                      variant={"h5"}
                      component={"h3"}
                      align={"center"}
                      className={classes.title}
                    >
                      {local.name}
                    </Typography>
                    <Typography
                      variant={"h5"}
                      component={"h5"}
                      align={"center"}
                      className={classes.subtitles}
                    >
                      {local.direction}
                    </Typography>
                  </Grid>
                  <Grid item md={6} >
                    <img src={local.photo} alt="imagen" className={classes.imgContainer} />
                  </Grid>
                </Grid>
              </Grid>
            )
          })
        }

      </Grid>

    </Grid>
  );
}