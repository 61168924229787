import React from "react";
import {
  Grid,
  Typography,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
} from "@material-ui/core";
import { Link } from "react-scroll";
import bird_1 from "../../../resources/images/bird_1.jpeg";
import Zoom from "react-reveal/Zoom";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing(4)}px 0px ${theme.spacing(4)}px`,
    maxWidth: "100%",
  },
  cardTitle: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    textTransform: "uppercase",
    color: theme.palette.common.white,
  },
  cardText: {
    color: theme.palette.common.white,
  },
  nameTitle: {
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  cost: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    textTransform: "uppercase",
    color: theme.palette.common.white,
  },
  title: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },
  avatar: {
    width: 100,
    height: 100,
    margin: "auto",
    // border: "solid 1px #003398",
  },
  tablecell: {
    fontSize: "11pt",
    textAlign: "center",
  },
}));

export default function Bird(props) {
  const classes = useStyles();

  const TableCellStyled = (props) => {
    return <TableCell {...props} className={classes.tablecell} />;
  };

  return (
    <Grid container spacing={4} className={classes.container} justify="center">
      <Grid item xs={12}>
        <Typography
          variant={"h4"}
          component={"h2"}
          align={"center"}
          className={classes.title}
        >
          PLAN <strong>{props.title}</strong>
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography
          variant={"h6"}
          component={"p"}
          align="center"
          className={classes.subtitle}
        >
          Enfocado a{" "}
          <strong style={{ color: "#003398" }}>
            micro y pequeñas empresas
          </strong>
          , que generen bajo volumen de material y ocasional. Según necesidad,
          pueden realizarse retiros adicionales.
        </Typography>
        <br />
      </Grid>
      <Grid item xs={12} md={8}>
        <div style={{ marginLeft: 20 }}>
          <Typography variant={"h6"} component={"p"} align={"justify"}>
            • Prediagnóstico gratuito
          </Typography>
          <Typography variant={"h6"} component={"p"} align={"justify"}>
            • 1 retiro al mes
          </Typography>
          <Typography variant={"h6"} component={"p"} align={"justify"}>
            • Kit de reciclaje para colaboradores
          </Typography>
          <Typography variant={"h6"} component={"p"} align={"justify"}>
            • Afiches de materiales para puntos limpios
          </Typography>
          <Typography variant={"h6"} component={"p"} align={"justify"}>
            • Reportes mensuales
          </Typography>
          <Typography variant={"h6"} component={"p"} align={"justify"}>
            • Retiro en vehículo menor realizado por recolector de base
          </Typography>
          <Typography variant={"h6"} component={"p"} align={"justify"}>
            • Retiros extraordinarios (opcional)
          </Typography>

          <br />
          <Typography variant={"h6"} component={"p"} align={"justify"}>
            * Por la contratación del servicio un año más 0,5 UF adicional a tu
            suscripción, podrás llevar el set de contenedores de colores para tu
            empresa. ¿Qué esperas?, ¡Comienza a reciclar con Acción Circular!
          </Typography>
          <br />
          <br />
          <br />

          <Typography variant={"h6"} component={"p"} align={"justify"} >
            * Valor de suscripción válido sólo para la Región Metropolitana
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} md={4}>
        <Zoom>
          <img src={bird_1} style={{ width: "100%" }} alt="plan" />
        </Zoom>
      </Grid>
      <Grid item xs={12} md={8} align="center">
        <Typography
          variant={"h5"}
          component={"h3"}
          align={"center"}
          className={classes.title}
        >
          Resumen <strong>plan bird</strong>
        </Typography>
        <br />
        <Card>
          <Table>
            <TableHead>
              <TableRow>
                <TableCellStyled>Retiros mensuales</TableCellStyled>
                <TableCellStyled>Reportabilidad</TableCellStyled>
                <TableCellStyled>Capacitación</TableCellStyled>
                <TableCellStyled>Plataforma</TableCellStyled>
                <TableCellStyled>Sensores</TableCellStyled>
                <TableCellStyled>Valor mensual</TableCellStyled>
                <TableCellStyled style={{ fontWeight: 600 }} > Plan anual</TableCellStyled>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCellStyled>1</TableCellStyled>
                <TableCellStyled>Sí</TableCellStyled>
                <TableCellStyled>Sí</TableCellStyled>
                <TableCellStyled>Sí</TableCellStyled>
                <TableCellStyled>No</TableCellStyled>
                <TableCellStyled>Desde 1.2 UF + IVA</TableCellStyled>
                <TableCellStyled>1 Retiro mensual por 19 UF + IVA</TableCellStyled>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
        <br />
        <br />
        <br />
        <Link
          activeClass="active"
          className="cards"
          to="cards"
          spy={true}
          smooth={true}
          duration={700}
        >
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={() => props.setSuscription(null)}
            endIcon={<ArrowUpwardIcon />}
          >
            Ver otros planes
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
}
