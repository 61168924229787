/* eslint-disable max-len */
import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import YouTube from "react-youtube";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "80px 0px 0px",
    },
  },
  title: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },
  subtitles: {
    margin: `0px 0px ${theme.spacing(4)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },
  body: {
    color: theme.palette.common.white,
  },
  itemTitle: {
    textTransform: "uppercase",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    color: theme.palette.common.white,
  },
  items: {
    margin: "40px",
  },
  img: {
    height: "128px",
  },
  imgContainer: {
    margin: `${theme.spacing(4)}px 0px ${theme.spacing(4)}px`,
    width: "100%",
    height: "128px",
    display: "flex",
    justifyContent: "center",
  },
}));

export default function Video() {
  const classes = useStyles();

  const [videoOpts, setVideoOpts] = React.useState({ width: "100%" });
  const videoDivRef = React.useRef(null);

  const handleResize = () => {
    const width = videoDivRef.current ? videoDivRef.current.offsetWidth : 0;
    setVideoOpts({ width, height: (width * 9) / 16 });
  };

  React.useEffect(() => {
    const width = videoDivRef.current ? videoDivRef.current.offsetWidth : 0;
    setVideoOpts({ width, height: (width * 9) / 16 });
  }, [videoDivRef.current]);

  React.useEffect(() => {
    // handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  function _onReady(event) {
    // access to player in all event handlers via event.target
    //event.target.mute();

    //event.target.pauseVideo();
  }

  return (
    <Grid item container justify="center" className={classes.container}>
      <Grid item xs={12} align="center" ref={videoDivRef}>
        <br />
        <YouTube
          videoId="JM3Lropp73Q"
          opts={{
            height: videoOpts.height,
            width: videoOpts.width,
            playerVars: {
              autoplay: 1,
              controls: 0,
            },
          }}
          onReady={_onReady}
        />
      </Grid>
    </Grid>
  );
}
