/* eslint-disable max-len */
import React from "react";
import {
  Grid,
  makeStyles,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

import img1 from "../../resources/icons/ordenador-portatil.png";
import img3 from "../../resources/icons/camion-de-reparto.png";
import img4 from "../../resources/icons/reporte.png";
import img5 from "../../resources/icons/flecha.png";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "#ffffff",
    backgroundSize: "cover",
    margin: `${theme.spacing(4)}px 0px 0px`,
    maxWidth: "100%",
  },
  title: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },
  subtitles: {
    margin: `0px 0px ${theme.spacing(4)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },
  body: {
    //color: "#003398",
  },
  itemTitle: {
    textTransform: "uppercase",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    color: "#003398",
  },
  img: {
    height: 90,
    opacity: 0.6,
  },
  imgArrow: {
    height: 50,
    opacity: 0.6,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
  },
  imgContainer: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(2)}px`,
    width: "100%",
    height: 90,
    display: "flex",
    justifyContent: "center",
  },
}));

export default function Esquema() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container direction="row" justify="center">
      <Grid item xs={12}>
        <Grid container justify="center">
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <Typography
              variant={"h4"}
              component={"h2"}
              align={"center"}
              className={classes.title}
            >
              ¿CÓMO <strong>FUNCIONA?</strong>
            </Typography>

            <Typography
              variant={"h6"}
              component={"p"}
              align="center"
              className={classes.subtitle}
            >
              Nuestra servicio en tres sencillos pasos
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={10}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={10} sm={3}>
            <div className={classes.imgContainer}>
              <img src={img1} alt="Solicitud" className={classes.img} />
            </div>
            <Typography
              className={classes.itemTitle}
              variant={"h5"}
              component={"h4"}
              align="center"
            >
              SOLICITUD
            </Typography>
            <Typography
              align="center"
              className={classes.body}
              variant={"h6"}
              component={"p"}
            >
              Solicita tu retiro mediante plataforma o sensores y espera
              confirmación
            </Typography>
          </Grid>
          <Grid item xs={12} sm={1}>
            <img
              src={
                img5
              }
              alt="arrow"
              className={classes.imgArrow}
            />
          </Grid>

          <Grid item xs={10} sm={3}>
            <div className={classes.imgContainer}>
              <img src={img3} alt="Recolector" className={classes.img} />
            </div>
            <Typography
              align="center"
              className={classes.itemTitle}
              variant={"h5"}
              component={"h4"}
            >
              RECOLECCIÓN
            </Typography>
            <Typography
              align="center"
              className={classes.body}
              variant={"h6"}
              component={"p"}
            >
              Espera a que el reciclador llegue al lugar y recolecte tus
              materiales
            </Typography>
          </Grid>
          <Grid item xs={12} sm={1}>
            <img
              src={
                img5
              }
              alt="arrow"
              className={classes.imgArrow}
            />
          </Grid>
          <Grid item xs={10} sm={3}>
            <div className={classes.imgContainer}>
              <img src={img4} alt="Reporte" className={classes.img} />
            </div>
            <Typography
              align="center"
              className={classes.itemTitle}
              variant={"h5"}
              component={"h4"}
            >
              REPORTABILIDAD
            </Typography>

            <Typography
              align="center"
              className={classes.body}
              variant={"h6"}
              component={"p"}
            >
              Monitorea la gestión de tus residuos en tiempo real, emite
              reportes cuando lo necesites
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
