import React from "react";
import MetaTags from "react-meta-tags";
import { CssBaseline, makeStyles, MuiThemeProvider } from "@material-ui/core";
import axios from "axios";
import { theme } from "./theme";
import { SnackbarProvider } from "notistack";
import ReactGA from "react-ga";

import { AppContextProvider, useAppContext } from "./context/AppContext";
import Routes from "./Routes";
import logo from "./resources/logo.png";
import Header from "./components/Navigation/Header";
import Footer from "./components/Navigation/Footer";
import Contact from "./components/Navigation/Contact";
import Whatsapp from "./components/Navigation/Whatsapp";
import "./App.css";
import tawkTo from "tawkto-react";
import { hotjar } from 'react-hotjar';

const tawkToPropertyId = '61a64c2a53b398095a66ca1d';
const tawkToKey = '1fmj7i2ma';

const useStyles = makeStyles((theme) => ({
  landingPageContainer: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "60px",
    },
    marginTop: "60px",
    maxWidth: "100%",
    overflowX: "hidden",
  },
}));

function App() {
  const classes = useStyles();
  const { section } = useAppContext();

  hotjar.initialize(2726674,6);

  React.useEffect(() => {
    ReactGA.initialize("UA-126990880-5");
    tawkTo(tawkToPropertyId, tawkToKey);
  }, []);

  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  return (
    <MuiThemeProvider theme={theme}>
      <MetaTags>
        <title>Acción Circular | {section}</title>

        <meta
          id="meta-description"
          name="Promovemos la economía circular"
          content="Somos una plataforma que gestiona y reporta el retiro de 
        residuos para su reciclaje conectando Puntos de Venta con Valorizadores de residuos.."
        />
        <meta id="og-title" property="og:title" content="Acción Circular" />
        <meta
          id="og-image"
          itemProp="image"
          property="og:image"
          content={logo}
        />
        <meta property="og:site_name" content="Acción Circular" />
        <meta
          property="og:description"
          content="Promovemos la economía circular"
        />
        <meta property="og:type" content="website" />
        <meta property="og:updated_time" content="1440432930" />
        <link itemProp="thumbnailUrl" href={logo} />
        <span
          itemProp="thumbnail"
          itemScope
          itemType="https://www.accioncircular.com/static/media/logo.c0df2e74.png"
        >
          <link
            itemProp="url"
            href="https://www.accioncircular.com/static/media/logo.c0df2e74.png"
          />
        </span>
      </MetaTags>
      <SnackbarProvider
        maxSnack={2}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <CssBaseline>
          <Header />
          <div className={classes.landingPageContainer}>
            <Routes />
            <Contact />
            <Footer />
          </div>
        </CssBaseline>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
}

export default () => (
  <AppContextProvider>
    <App />
  </AppContextProvider>
);
