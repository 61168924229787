import React from "react";
import Header from "./Header";
import Equipo from "./Equipo";
import Orgullo from "./Orgullo";
import { animateScroll as scroll } from "react-scroll";
import { useAppContext } from "../../context/AppContext";
import { Element } from "react-scroll";
import ReactGA from "react-ga";
import Leyrep from "../../components/Navigation/leyrep";

export default function Index() {
  const { setSection } = useAppContext();
  const [imagen, setImagen] = React.useState(true);

  React.useEffect(() => {
    scroll.scrollToTop();
    setSection("Circulares");
    ReactGA.pageview("/circulares");
  });
  return (
    <div>
	{imagen && (
		<Leyrep />
	  )}
      <Header />
      <Element name="equipo">
        <Equipo />
      </Element>
      <Element name="orgullo">
        <Orgullo />
      </Element>
    </div>
  );
}
