import React, { useState, useEffect } from "react";
import { Fab, Zoom, makeStyles } from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
const useStyles = makeStyles((theme) => ({
  container: {
    margin: 0,
    top: "auto",
    right: "3%",
    bottom: "3%",
    left: "auto",
    position: "fixed",
    zIndex: 10000,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    color: theme.palette.common.white,
    backgroundColor: "#25D366",
    "&:hover": {
      backgroundColor: "#25D366",
    },
  },
}));

export default function ToTopButton() {
  const classes = useStyles();

  useEffect(() => {
    const handleScroll = () => {
      setBtnIn(
        document.documentElement.scrollTop >
          document.documentElement.clientHeight / 10
      );
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [btnIn, setBtnIn] = useState(false);

  const openWhatsapp = () => {
    window.open("https://wa.me/56932700220");
  };

  return (
    <div className={classes.container}>
      <Zoom
        in={btnIn}
        timeout={500}
        style={{
          transitionDelay: "200ms",
        }}
      >
        <Fab
          variant="extended"
          size="large"
          className={classes.fab}
          aria-label="Nuevo local"
          onClick={openWhatsapp}
        >
          <WhatsAppIcon fontSize="large" />
        </Fab>
      </Zoom>
    </div>
  );
}
