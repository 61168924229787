import React, { useState } from "react";
import ReactMapGL, {
  Marker,
  Popup,
} from "react-map-gl";  // ONLY USE v5.2.11

import PropTypes from "prop-types";

import LocalPin from "./LocalPin";
import LocalInfo from "./LocalInfo";

const token = process.env.REACT_APP_MAPGL_API_KEY;

export default function Map({ locals }) {
  const [info, setInfo] = useState(null);

  const [viewport, setViewport] = useState({
    latitude: -33.418948,
    longitude: -70.577940,
    zoom: 14,
  });

  const renderPopup = () => {
    return (
      info && (
        <Popup
          tipSize={5}
          anchor="top"
          longitude={info.position.longitude}
          latitude={info.position.latitude}
          closeOnClick={false}
          onClose={() => setInfo(null)}
        >
          <LocalInfo info={info} />
        </Popup>
      )
    );
  }
  return (
    <ReactMapGL
      mapboxApiAccessToken={"pk.eyJ1Ijoiam9zZXBlZHJhemEiLCJhIjoiY2sxZWpjMGcyMGprZjNjbXZvaXowMHJibCJ9.T2vE902XE8a_WVB3--0Ssg"}
      {...viewport}
      width="50vw"
      height="90vh"
      mapStyle={
        "mapbox://styles/mapbox/light-v10"
      }
      onViewportChange={(viewport) => setViewport(viewport)}
      style={{border: "2px solid #003398"}}
    >
      {locals.map((local, index) => {
        return (
          <Marker
            key={`marker-${index}`}
            latitude={local.position.latitude}
            longitude={local.position.longitude}
          >
            <LocalPin size={20} onClick={() => setInfo(local)} />
          </Marker>)
      })}
      {renderPopup()}
    </ReactMapGL>
  );
}

Map.propTypes = {
  locals: PropTypes.array.isRequired,
};
