import React from "react";
import { Grid, Button, makeStyles } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const useStyles = makeStyles((theme) => ({
  navlink: {
    textDecoration: "none",
    color: "#000",
    "&:hover": {
      opacity: 0.5,
    }
  },
}));

const active = {
  fontWeight: "bold",
  color: "#01579B",
  textDecoration: "none",
};
//export default function LandingPageAppBar({ setCoti,setContacto}) {
export default function LandingPageAppBar({ setCoti}) {
  const classes = useStyles();
  return (
    <Grid container direction="row" spacing={2} alignItems="center">
      <Grid item>
        <NavLink to="/" className={classes.navlink} activeStyle={active} exact>
          HOME
        </NavLink>
      </Grid>

      <Grid item>
        <NavLink
          to="/servicios"
          className={classes.navlink}
          activeStyle={active}
        >
          SERVICIOS
        </NavLink>
      </Grid>
      <Grid item>
        <NavLink
          to="/circulares"
          className={classes.navlink}
          activeStyle={active}
        >
          CIRCULARES
        </NavLink>
      </Grid>
      <Grid item>
        <NavLink
          to="/noticias"
          className={classes.navlink}
          activeStyle={active}
        >
          NOTICIAS
        </NavLink>
      </Grid>
      <Grid item>
        <NavLink
          to="/reciclamos"
          className={classes.navlink}
          activeStyle={active}
        >
          RECICLAMOS
        </NavLink>
      </Grid>
      <Grid item>
        
          <Button
            variant="outlined"
            color="primary"
            onClick={()=>setCoti(true)}
            className={classes.button}
          >
            {"Cotiza aquí"}
          </Button>
        
      </Grid>
	  { 
	  /*
	  <Grid item>
        
          <Button
            variant="outlined"
            color="primary"
            onClick={()=>setContacto(true)}
            className={classes.button}
          >
            {"Ley REP"}
          </Button>
        
      </Grid>
	  */
	  }
      <Grid item>
        <a
          href="https://platform.accioncircular.com"
          className={classes.navlink}
        >
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            startIcon={<AccountCircleIcon />}
          >
            {"Ingresar"}
          </Button>
        </a>
      </Grid>
    </Grid>
  );
}
