import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
} from "@material-ui/core";

import accion_circular from "../../../resources/reciclamos/logo_accion_circular.png"
import vital from "../../../resources/reciclamos/logo_vital.jpg"
import oxxo from "../../../resources/reciclamos/logo_oxxo.png"

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing(4)}px 0px ${theme.spacing(4)}px`,
    maxWidth: "100%",
  },
  imgContainer: {
    float: 'center',
    width: '195px',
    height: '100px',
    objectFit: 'cover',
  },
}));

export default function index() {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.container}
      direction="row"
    >
      <Grid item xs={12}>
        <Grid
          container
          spacing={1}
        >
          <Grid item md={4} align="right">
            <img
              className={classes.imgContainer}
              src={vital}
              alt="vital"
            />
          </Grid>
          <Grid item md={4} align="center">
            <img
              className={classes.imgContainer}
              src={accion_circular}
              alt="a-c"
            />
          </Grid>
          <Grid item md={4} align="left">
            <img
              className={classes.imgContainer}
              src={oxxo}
              alt="oxxo"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

