import React from "react";
import IconCross from "./../Icons/IconCross";
import "./Content.scss";
//import CloseIcon from "@material-ui/icons/Close";

const Content = ({ movie, onClose }) => (
  <div className="content">
    <div className="content__background">
      <div className="content__background__shadow" />
      <div
        className="content__background__image"
        style={{ "background-image": `url(${movie.imageBg})` }}
      />
    </div>
    <div className="content__area">
      <div className="content__area__container">
        <div className="content__title">{movie.title}</div>
        <div className="content__description">{movie.description}</div>
      </div>
      <button className="content__close" onClick={onClose}>
        <IconCross fontSize="large" />
      </button>
    </div>
  </div>
);

export default Content;
