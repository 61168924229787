import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
} from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles((theme) => ({
  container: {
    // margin: `${theme.spacing(4)}px 0px ${theme.spacing(4)}px`,
    maxWidth: "100%",
  },
  title: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },
  subtitles: {
    margin: `0px 0px ${theme.spacing(4)}px`,
  },
  imgContainer: {
    display: "inline-block",
    height: "100%",
    verticalAlign: "middle",
  },
  img: {
    margin: `${theme.spacing(6)}px ${theme.spacing(5)}px ${theme.spacing(5)}px`,
    width: "55%",
    verticalAlign: "middle",
  },
  cards: {
    verticalAlign: "left",
  },
  details: {
    display: "flex",
    alignItems: "center",
    margin: "auto",
    padding: "7% 2em",
  },
  warningBox: {
    width: "67%",
    border: "double 5px transparent",
    borderRadius: "10px",
    backgroundImage: "linear-gradient(white, white), radial-gradient(circle at top left, red, orange)",
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
    textAlign: "left",
  },
  warning: {
    display: "flex",
    alignItems: "center",
    padding: "3px"
  },
  warningIcon: {
    width: "40%",
    fill: "-webkit-linear-gradient(red, orange)",
  },
  warningText: {
    fontSize: "14px",
    background: "-webkit-linear-gradient(red, orange)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    textAlign: "justify"
  },
  cardsIcon: {
    width: "45%",
    height: "25%",
    borderRadius: "3%",
  },
  detailsIcon: {
    width: "25%",
  },
}));

const materials = [
  {
    name: "CELULOSA",
    icon: require("../../../resources/reciclamos/materiales/papel_y_carton.png"),
    background: "#354795",
    materials: [
      "Diarios y revistas",
      "Cartón dúplex",
      "Papeles de colores",
      "Hojas impresas",
      "Fotocopias",
      "Hojas de cuaderno",
      "Papel kraft",
      "Cajas de embalaje",
      "Envases de cartón liso y corrugado"
    ],
    cards: [],
    how: {
      title: "Retira los corchetes y las cintas adhesivas en caso de que cuente con esto el material. Desarmar y compactar.",
      warnings: [],
      details: [{
        title: "No reciclamos: Papel calco, papeles termolaminados, papel con aceite, papel con adhesivo y papel fotográfico",
        icon: require("../../../resources/reciclamos/assets/denied.png")
      }],
    }
  },
  {
    name: "PLÁSTICOS",
    icon: require("../../../resources/reciclamos/materiales/plasticos.png"),
    background: "#efc546",
    materials: [
      "Botellas (PET 1)",
      "Clamshell (PET 1)",
      "Envases y vasos desechables (PET 1 y PP 5)",
      "Envases rígidos (PEAD 2 y PP 5)",
      "Envases flexibles (PEBD 4)",
      "Tapas plásticas",
      "Strech film",

    ],
    cards: [
      {
        title: "Reciclables",
        icon: require("../../../resources/reciclamos/materiales/reciclables.png")
      },
      {
        title: "No reciclables",
        icon: require("../../../resources/reciclamos/materiales/no_reciclables.png")
      },
    ],
    how: {
      title: "Identifica el tipo de plástico buscando el triángulo con el número en su interior. Luego enjuaga y elimina todos los residuos, saca etiquetas, deja secar y compacta.",
      warnings: [
        {
          title: "Hay que evitar el uso de plásticos 3, 6 y 7.",
          icon: "",
        }],
      details: [
        {
          title: "Botellas de aceite y vinagre.",
          icon: require("../../../resources/reciclamos/assets/denied.png")
        },
      ],
    }
  },
  {
    name: "ALUMINIO Y OTROS METALES",
    icon: require("../../../resources/reciclamos/materiales/conservas_y_latas.png"),
    background: "#5e5c5f",
    materials: [
      "Latas de bebidas",
      "Latas de conservas",
      "Tapas metálicas",
      "Chatarra metálica",
      "Partes y piezas de metal",
    ],
    cards: [
      {
        title: "Busca este símbolo.",
        icon: require("../../../resources/reciclamos/assets/alu.png")
      },
    ],
    how: {
      title: "Vaciar el contenido, enjuagar, dejar secar y luego compactar.",
      warnings: [],
      details: [
        {
          title: "Latas de aerosol, tarros de pintura, tarros de pegamento.",
          icon: require("../../../resources/reciclamos/assets/denied.png")
        },
      ],
    }
  },
  {
    name: "TETRA PAK",
    icon: require("../../../resources/reciclamos/materiales/tetra_pak.png"),
    background: "#887765",
    materials: [
      "Cajas de jugo o leche",
      "Cajas de conservas",
    ],
    cards: [
      {
        title: "Busca este símbolo.",
        icon: require("../../../resources/reciclamos/assets/tetrapak.png")
      }
    ],
    how: {
      title: "Levanta las pestañas, enjuaga el interior y elimina todos los restos. Deja secar y compacta.",
      warnings: [],
      details: [],
    },
  },
  {
    name: "VIDRIO",
    icon: require("../../../resources/reciclamos/materiales/botella_y_conserva.png"),
    background: "#377c41",
    materials: [
      "Botellas",
      "Frascos de conserva",
    ],
    cards: [],
    how: {
      title: "Retira etiquetas y tapas, enjuagar. Prefiere los productos que vengan contenidos en vidrio, es un material que puede ser reprocesado muchas veces.",
      warnings: [],
      details: [{
        title: "Vasos, copas, espejos, ampolletas, loza.",
        icon: require("../../../resources/reciclamos/assets/denied.png")
      }],
    }
  },
  {
    name: "RETIROS EXTRAORDINARIOS",
    icon: require("../../../resources/reciclamos/materiales/extras.png"),
    background: "#321d70",
    materials: [
      "Madera",
      "Plumavit blanca",
      "Electrónicos, computacionales y componentes",
    ],
    cards: [],
    how: {
      title: "",
      warnings: [],
      details: [],
    },
  },
];

export default function index() {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={4}
      className={classes.container}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid item align="center">
        <Typography
          variant={"h4"}
          component={"h3"}
          align={"center"}
          className={classes.title}
        >
          ¿Qué materiales <strong> gestionamos? </strong>
        </Typography>
        <br />
        <Typography
          variant={"h6"}
          component={"p"}
          align="justify"
          className={classes.subtitle}
        >
          Todos los materiales <strong style={{ color: "#003398" }}> deben venir limpios, sin etiquetas, scotch, restos de comida o {" "}
          manchados con aceite </strong>
        </Typography>
        <Typography
          variant={"h6"}
          component={"p"}
          align="center"
          className={classes.subtitle}
        >
          y correctamente segregados de acuerdo a cada <strong style={{ color: "#003398" }}> grupo {" "}
          de materiales.</strong>
        </Typography>
      </Grid>

      <Grid item xs={12} md={10} align="center">
        <hr />
        {materials.map((material, i) => (
          <div key={i}>
            <Item material={material} />
            <hr />
          </div>
        ))}
      </Grid>
    </Grid>
  );
}


const Item = ({ material }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
    // justify="space-between"
    // alignItems="center"
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid item xs={4}>
            <Typography
              variant={"h4"}
              component={"h3"}
              align={"center"}
              style={{ backgroundColor: material.background, color: "white" }}
            // style={{ color: material.background} }
            >
              {material.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={4} style={{ paddingLeft: "5%" }}>
          {material.materials.map((material, i) => (
            <div key={`material${i}`} style={{ margin: "0px", padding: "0px" }}>
              <ul style={{ textAlign: "left" }}>
                <li >
                  <Typography>
                    {material}
                  </Typography>
                </li>
              </ul>
            </div>
          ))}
          {material.cards &&
            material.cards.map((card, i) => (
              <Grid key={`card${i}`} className={classes.cards}>
                <Grid item xs={12} style={{
                  paddingTop: "30px",
                }}>
                  <Typography style={{ fontSize: "13px", textAlign: "left", paddingLeft: "18px" }}>
                    <FiberManualRecordIcon fontSize="small" style={{ fill: material.background }} />
                    {card.title}
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "left", paddingLeft: "20px" }}>
                  <img
                    src={card.icon}
                    alt="image_card"
                    className={classes.cardsIcon}
                    style={material.name === "ALUMINIO Y OTROS METALES" || material.name === "TETRA PAK" ? { width: "30%", display: "block", marginLeft:"20%", marginRight: "80%", marginTop:"5px" } : {}}
                  />
                </Grid>
              </Grid>
            ))}
        </Grid>
        <Grid item xs={4} >
          {material.how.title &&
            <Grid item xs={4}>
              <Typography style={{ backgroundColor: material.background, color: "white", fontSize: "18px" }}>
                {/* <Typography style={{ color: material.background }}> */}
                ¿Cómo reciclar?
              </Typography>
            </Grid>
          }
          <Grid item xs={6} style={{ paddingTop: "20px" }} >
            <Typography style={{ textAlign: "justify", paddingBottom: "5px", fontSize: "16px" }}>
              {material.how.title}
            </Typography>
          </Grid>
          {material.how.warnings &&
            material.how.warnings.map((warning, i) => (
              <Grid key={`warnings${i}`} style={{ margin: 10 }} className={classes.warningBox}>
                <Grid item xs={12} className={classes.warning} >
                  <ErrorOutlineIcon fontSize="large" style={{ fill: "orange" }} />
                  <Typography style={{ textAlign: "justify", paddingLeft: "15px" }} className={classes.warningText}> {warning.title}</Typography>
                </Grid>
              </Grid>
            ))}
          {material.how.details &&
            material.how.details.map((detail, i) => (
              <Grid item xs={10} className={classes.details} key={`detail${i}`}>
                <img src={detail.icon} alt="image_icon" className={classes.detailsIcon} />
                <Typography style={{ textAlign: "justify", paddingLeft: "15px", paddingRight: "25%" }}>{detail.title}</Typography>
              </Grid>
            ))}
        </Grid>
        <Grid item xs={4} style={{ paddingRight: "5%" }}>
          <div className={classes.imgContainer}>
            <img src={material.icon} alt="image_material" className={classes.img} />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
