import { green, red, yellow } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core";
// * -------------- Tema --------------

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#01579B",
    },
    secondary: {
      main: "#1E1D28",
    },
  },

  dashboardPaper: {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px 30px 30px",
    minWidth: "100%",
  },
  typography: {
    fontSize: 12,
    useNextVariants: true,
    fontFamily: '"Questrial", sans-serif',
  },

  greenBtn: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[700],
    },
    "&:disabled": {
      backgroundColor: green[300],
    },
  },

  yellowBtn: {
    backgroundColor: yellow[700],
    color: "#fff",
    "&:hover": {
      backgroundColor: yellow[900],
    },
  },

  redBtn: {
    backgroundColor: red[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: red[700],
    },
  },

  blueBtn: {
    backgroundColor: "#01579B",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#003c6c",
    },
  },
});
