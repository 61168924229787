import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";

import agora from "../../resources/partners/agora partners.png";
import amusa from "../../resources/partners/amusa partners .jpg";
import clp from "../../resources/partners/co lab partners.png";
import entel from "../../resources/partners/entel partners.png";
import ifc from "../../resources/partners/if partners.png";
import sec from "../../resources/partners/se partners.png";
import Slider from "../../components/ImageSlider";

const images = [agora, amusa, clp, entel, ifc, sec];
const useStyles = makeStyles((theme) => ({
  sponsorContainer: {
    pointerEvents: "none",
  },
  title: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },
}));

export default function Sponsors() {
  const classes = useStyles();

  return (
    <Grid container className={classes.sponsorContainer}>
      <Grid item xs={12}>
        <Grid container alignContent={"center"} justify={"center"}>
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <br />
            <Typography
              variant={"h4"}
              component={"h2"}
              align={"center"}
              className={classes.title}
            >
              nuestros <strong>partners</strong>
            </Typography>
            <br />
          </Grid>
        </Grid>
        <br />
        <Slider images={images} />
      </Grid>
    </Grid>
  );
}
