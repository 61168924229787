import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

import pauli_mercurio from "../../../resources/noticias/pauli_mercurio.png";
import accion_circular_equipo from "../../../resources/noticias/accion_circular_equipo.jpg";
import smart_city_awards_2019 from "../../../resources/noticias/smart_city_awards_2019.jpg";
import pauli_paul from "../../../resources/noticias/pauli_paul.jpg";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: `${theme.spacing(4)}px`,
    marginBottom: `${theme.spacing(4)}px`,
    background: "#fff",
  },
  textContainer: {
    padding: 20,
  },
  title: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },
  imgContainer: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(2)}px`,
    width: "100%",
  },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

const notas = [
  {
    title: "Con una plataforma y sensores emprendedora chilena busca volver “smart” el proceso del reciclaje",
    date: "31 de Diciembre de 2020",
    image: pauli_mercurio,
    text: ``,
    links: [
      {
        pre: "Link a la nota",
        link:
          "https://digital.elmercurio.com/2020/12/31/ME-I/OA3TADQ2#zoom=page-width",
      },
    ],
  },
  {
    title: "Acción Circular: El emprendimiento tecnológico que automatiza y optimiza la gestión de residuos",
    date: " 8 de Noviembre de 2020",
    text: ``,
    image: accion_circular_equipo,
    links: [
      {
        pre: "Link a la nota",
        link:
          "https://chileglobalventures.cl/accion-circular-el-emprendimiento-tecnologico-que-automatiza-y-optimiza-la-gestion-de-residuos/ ",
      },
    ],
  },
  {
    title: "El emprendimiento y la innovación fueron premiados en los Smart City Awards",
    date: "16 de Enero de 2020",
    text: ``,
    image: smart_city_awards_2019,
    links: [
      {
        pre: "Link a la nota",
        link:
          "https://www.paiscircular.cl/ciudad/el-emprendimento-y-lo-innovacion-fueron-premiados-en-los-smart-city-awards/",
      },
    ],
  },
  {
    title: "Acción Circular: la solución tecnológica que democratiza la cadena del reciclaje",
    date: "16 de Agosto de 2019",
    text: ``,
    image: pauli_paul,
    links: [
      {
        pre: "Link a la nota",
        link: "https://www.paiscircular.cl/consumo-y-produccion/accion-circular-la-solucion-tecnologica-que-democratiza-la-cadena-del-reciclaje/",
      }
    ],
  }
];
export default function Equipo() {
  const classes = useStyles();

  return (
    <Grid container justify="center" spacing={6} className={classes.container}>
      <Grid item xs={12}>
        <Typography
          variant={"h4"}
          component={"h2"}
          align={"center"}
          className={classes.title}
        >
          <strong>Acción Circular</strong> en la prensa
        </Typography>
      </Grid>
      <Grid item xs={12} md={10} align="center">
        <hr />
        {notas.map((data, i) => (
          <div>
            <Item key={i} data={data} />
            <hr />
          </div>
        ))}
      </Grid>
    </Grid>
  );
}

const Item = ({ data }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={9}>
        <Typography
          variant={"h5"}
          component={"h3"}
          align={"center"}
          className={classes.title}
        >
          {data.title}
        </Typography>
        <Typography
          variant={"h6"}
          component={"h6"}
          align={"center"}
          className={classes.title}
        >
          {data.date}
        </Typography>
        {data.text.split("\n").map((paragraph, i) => (
          <div key={"paragraph_nuestro_orgullo" + i} style={{ margin: 20 }}>
            <Typography component={"p"} align={"justify"}>
              {paragraph}
            </Typography>
          </div>
        ))}
        {data.links.map((link, i) => (
          <div>
            <a key={"link_nuestro_orgullo" + i} href={link.link}>
              {link.pre}
            </a>
            <br />
          </div>
        ))}
      </Grid>
      <Grid item xs={12} md={3}>
        <img src={data.image} alt="imagen" className={classes.imgContainer} />
      </Grid>
    </Grid>
  );
};
