import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";

import Corfo from "../../resources/sponsors/Partner corfo-01.png";
import SistemaB from "../../resources/sponsors/Partner SistemaB-01.png";
import SUP from "../../resources/sponsors/partner-sup.png";
import CGV from "../../resources/sponsors/chile global v.png";
import BPendiente from "../../resources/sponsors/partner_logo_b_pendiente.png";
import Sello_PLV from "../../resources/sponsors/partner_SELLO_COLOR_PLV.png";
import Slider from "../../components/ImageSlider";

const useStyles = makeStyles((theme) => ({
  sponsorContainer: {
    pointerEvents: "none",
  },
  title: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },
}));

export default function Sponsors() {
  const classes = useStyles();

  return (
    <Grid container className={classes.sponsorContainer}>
      <Grid item xs={12}>
        <Grid container alignContent={"center"} justify={"center"}>
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <br />
            <Typography
              variant={"h4"}
              component={"h2"}
              align={"center"}
              className={classes.title}
            >
              somos <strong>respaldados</strong>
            </Typography>
            <br />
          </Grid>
        </Grid>
        <br />
        <Slider images={[Corfo, SistemaB, SUP, CGV, BPendiente, Sello_PLV]} />
      </Grid>
    </Grid>
  );
}
