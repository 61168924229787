import React from "react";
import {
  Grid,
  Typography,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
} from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { Link } from "react-scroll";
import whale_2 from "../../../resources/images/whale_2.jpeg";
import Zoom from "react-reveal/Zoom";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing(8)}px 0px ${theme.spacing(8)}px`,
    maxWidth: "100%",
  },
  cardTitle: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    textTransform: "uppercase",
    color: theme.palette.common.white,
  },
  cardText: {
    color: theme.palette.common.white,
  },
  nameTitle: {
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  cost: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    textTransform: "uppercase",
    color: theme.palette.common.white,
  },
  title: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },
  avatar: {
    width: 100,
    height: 100,
    margin: "auto",
    // border: "solid 1px #003398",
  },
  tablecell: {
    fontSize: "11pt",
    textAlign: "center",
  },
}));

export default function Bird(props) {
  const classes = useStyles();

  const TableCellStyled = (props) => {
    return <TableCell {...props} className={classes.tablecell} />;
  };

  return (
    <Grid container spacing={4} className={classes.container} justify="center">
      <Grid item xs={12}>
        <Typography
          variant={"h4"}
          component={"h2"}
          align={"center"}
          className={classes.title}
        >
          PLAN <strong>{props.title}</strong>
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography variant={"h6"} component={"p"} align={"justify"}>
          <strong style={{ color: "#003398" }}>
            Medianas y grandes empresas
          </strong>
          , generalmente de naturaleza productiva, que generan residuos de
          manera constante y en altos volúmenes, además de cumplir especiales
          permisos legales para la gestión de sus residuos.
        </Typography>
        <Typography variant={"h6"} component={"p"} align={"justify"}>
          Cuentan con patios de acopio y pueden implementar contenedores de más
          de 1.100 litros de capacidad en sus patios, incluso tolvas completas.
        </Typography>
        <Typography variant={"h6"} component={"p"} align={"justify"}>
          Se les asigna transporte a través de camiones ¾ o más grande, incluso
          la vinculación directa con empresas de reciclaje que puedan instalar
          tolvas.
        </Typography>
        <br />
        <div style={{ marginLeft: 20 }}>
          <Typography variant={"h6"} component={"p"} align={"justify"}>
            • Diagnóstico de residuos. (opcional recomendado)
          </Typography>
          <Typography variant={"h6"} component={"p"} align={"justify"}>
            • Taller de capacitación. (opcional recomendado)
          </Typography>
          <Typography variant={"h6"} component={"p"} align={"justify"}>
            • Sobre 4 retiro al mes
          </Typography>
          <Typography variant={"h6"} component={"p"} align={"justify"}>
            • Kit de reciclaje para colaboradores
          </Typography>
          <Typography variant={"h6"} component={"p"} align={"justify"}>
            • Afiches de materiales para puntos limpios
          </Typography>
          <Typography variant={"h6"} component={"p"} align={"justify"}>
            • Reportes mensuales. (creación de usuario en plataforma)
          </Typography>
          <Typography variant={"h6"} component={"p"} align={"justify"}>
            • Retiro de camión 3/4 de recolector de base certificado
          </Typography>
        </div>
        <br />
      </Grid>
      <Grid item xs={12} md={4}>
        <Zoom>
          <img src={whale_2} style={{ width: "100%" }} alt="plan" />
        </Zoom>
      </Grid>
      <Grid item xs={12} md={8} align="center">
        <Typography
          variant={"h5"}
          component={"h3"}
          align={"center"}
          className={classes.title}
        >
          Resumen <strong>plan Whale</strong>
        </Typography>
        <br />

        <Card>
          <Table>
            <TableHead>
              <TableRow>
                <TableCellStyled>Retiros mensuales</TableCellStyled>
                <TableCellStyled>Reportabilidad</TableCellStyled>
                <TableCellStyled>Capacitación</TableCellStyled>
                <TableCellStyled>Plataforma</TableCellStyled>
                <TableCellStyled>Sensores</TableCellStyled>
                <TableCellStyled>Valor mensual</TableCellStyled>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCellStyled>Ilimitados (sobre 4)</TableCellStyled>
                <TableCellStyled>Sí</TableCellStyled>
                <TableCellStyled>Sí</TableCellStyled>
                <TableCellStyled>Sí</TableCellStyled>
                <TableCellStyled>opcional</TableCellStyled>
                <TableCellStyled>Personalizado</TableCellStyled>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
        <br />
        <br />
        <br />
        <Link
          activeClass="active"
          className="cards"
          to="cards"
          spy={true}
          smooth={true}
          duration={700}
        >
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={() => props.setSuscription(null)}
            endIcon={<ArrowUpwardIcon />}
          >
            Ver otros planes
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
}
