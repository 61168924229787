import React from "react";

import { animateScroll as scroll } from "react-scroll";
import { Element } from "react-scroll";
import ReactGA from "react-ga";

import { useAppContext } from "../../context/AppContext";
import Header from "./Header";
import Materiales from "./Materiales";
import BuenVecino from "./BuenVecino";
import Leyrep from "../../components/Navigation/leyrep";


export default function Index() {
  const { setSection } = useAppContext();
  const [imagen, setImagen] = React.useState(true);

  React.useEffect(() => {
    scroll.scrollToTop();
    setSection("Reciclamos");
    ReactGA.pageview("/reciclamos");
  });

  return (
    <div>
      <Header />
	  {imagen && (
		<Leyrep />
	  )}
      <Element name="materiales">
        <Materiales />
      </Element>
      <Element name="buenVecino">
        <BuenVecino />
      </Element>
    </div>
  );
}
