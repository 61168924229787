import React, {useState} from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import ReactGA from "react-ga";
import {
  Grid,
  Button,
  ButtonGroup,
  Typography,
  makeStyles,
  TextField,
  Dialog,
} from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import CloseIcon from "@material-ui/icons/Close";


const useStyles = makeStyles((theme) => ({
  title: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },
  error: {
    color: "red",
  },
  img: {
    height: 90,
    filter: "invert(100%);",
    opacity: 0.6,
    margin: 10,
  },
}));

const useInputStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #e2e2e1",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "#fcfcfb",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
}));
export default function Index({ coti, setCoti }) {
  const { enqueueSnackbar } = useSnackbar();
  const inputStyles = useInputStyles();
  const classes = useStyles();
  const [contactForm, setContactForm] = useState({
    name: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleClick = () => {
    if (
      !contactForm.name ||
      !contactForm.lastName ||
      !contactForm.email ||
      !contactForm.message
    ) {
      enqueueSnackbar(
        "Debes completar todos los campos antes de enviar el email",
        {
          variant: "error",
        }
      );
    } else {
      axios
        .post("/landing/contact", {
          email: contactForm.email,
          name: contactForm.name,
          lastName: contactForm.lastName,
          phone: contactForm.phone,
          message: contactForm.message,
        })
        .then((res) => {
          setContactForm({
            name: "",
            lastName: "",
            email: "",
            phone: "",
            message: "",
          });
          enqueueSnackbar("¡Mensaje enviado correctamente!", {
            variant: "success",
            autoHideDuration: "6000",
          });
          ReactGA.event({
            category: "Landing",
            action: "Envío email contacto",
          });
        })
        .catch((err) => {
          enqueueSnackbar("No se pudo enviar el email!", {
            variant: "error",
            autoHideDuration: "6000",
          });
        });
    }
  };

  const setByName = (event) =>
    setContactForm({ ...contactForm, [event.target.name]: event.target.value });

  return (
    <Dialog
      open={coti}
      onClose={() => setCoti(false)}
      fullWidth
      maxWidth="sm"
      style={{
        zIndex: 10000,
      }}
    >
      <Grid container justify="center" style={{ padding: 10 }}>
        <Grid item>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12} align="center">
              <Typography
                variant={"h4"}
                component={"h3"}
                align={"center"}
                className={classes.title}
              >
                Cotiza <strong>aquí</strong>
              </Typography>
              <br></br>
            
            </Grid>
            <Grid item md={7}>
          <Grid container justify="center">
            <Grid item xs={12}>
              <Typography
                variant={"h6"}
                align="center"
                className={classes.contactTitle}
              >
                {"¡Seamos Circulares!"}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="contact_name_1"
                label="Nombre"
                name="name"
                value={contactForm.name}
                onChange={setByName}
                margin="dense"
                variant="filled"
                fullWidth
                required
                InputProps={{ classes: inputStyles, disableUnderline: true }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="contact_lastname_1"
                label="Apellido"
                name="lastName"
                value={contactForm.lastName}
                onChange={setByName}
                margin="dense"
                variant="filled"
                fullWidth
                required
                InputProps={{ classes: inputStyles, disableUnderline: true }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="contact_email_1"
                label="Email"
                name="email"
                type="email"
                value={contactForm.email}
                onChange={setByName}
                margin="dense"
                variant="filled"
                fullWidth
                required
                InputProps={{ classes: inputStyles, disableUnderline: true }}
              />
            </Grid>

            {/* Telefono */}

            <Grid item xs={12}>
              <TextField
                id="contact_phone_1"
                label="Teléfono (Opcional)"
                name="phone"
                type="phone"
                value={contactForm.phone}
                onChange={setByName}
                margin="dense"
                variant="filled"
                fullWidth
                InputProps={{ classes: inputStyles, disableUnderline: true }}
              />
            </Grid>

            {/* Mensaje */}

            <Grid item xs={12}>
              <TextField
                id="contact_message_1"
                label="Mensaje"
                name="message"
                value={contactForm.message}
                onChange={setByName}
                margin="dense"
                variant="filled"
                multiline
                fullWidth
                required
                InputProps={{ classes: inputStyles, disableUnderline: true }}
                rows="3"
              />
            </Grid>

          </Grid>
        </Grid>
            <Grid item xs={12} sm={10} align="center">
              <br />
              
                  <ButtonGroup variant="contained" size="large" fullWidth>
                    <Button
                      color="primary"
                      onClick={() => setCoti(false)}
                      startIcon={<CloseIcon />}
                    >
                      Salir
                  </Button>
                  <Button
                      color="primary"
                      onClick={handleClick} 
                    >
                      Enviar
                  </Button>
                  </ButtonGroup>
                
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}
