import React from "react";
import { Grid } from "@material-ui/core";

export default function Footer() {
  return (
    <Grid container style={{ height: "20px", marginTop: "-1px" }}>
      <Grid item xs={3} style={{ backgroundColor: "#FFCB00" }} />
      <Grid item xs={3} style={{ backgroundColor: "#CB0000" }} />
      <Grid item xs={3} style={{ backgroundColor: "#33CB00" }} />
      <Grid item xs={3} style={{ backgroundColor: "#003398" }} />
    </Grid>
  );
}
