import React from "react";
import { Grid, makeStyles, Typography, Button } from "@material-ui/core";
import BarChartIcon from "@material-ui/icons/BarChart";
const useStyles = makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing(4)}px 0px ${theme.spacing(4)}px`,
    maxWidth: "100%",
  },
  title: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },
  subtitles: {
    margin: `0px 0px ${theme.spacing(4)}px`,
  },
}));

export default function index({ setAutodiagnostico }) {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={4}
      className={classes.container}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid item align="center">
        <Typography
          variant={"h4"}
          component={"h3"}
          align={"center"}
          className={classes.title}
        >
          ¿Quiénes pueden reciclar con <strong>Acción Circular</strong>?
        </Typography>
        <br />
        <Typography
          variant={"h6"}
          component={"p"}
          align="justify"
          className={classes.subtitle}
        >
          Todas las empresas son bienvenidas, lo importante es que cuentes con
          un{" "}
          <strong style={{ color: "#003398" }}>
            espacio de acopio y con mínimo 4 contenedores
          </strong>{" "}
          para segregar tus materiales.
        </Typography>
        <Typography
          variant={"h6"}
          component={"p"}
          className={classes.subtitle}
          align="center"
        >
          Revisa cuál es el plan más adecuado para ti, según tu{" "}
          <strong
            onClick={() => setAutodiagnostico(true)}
            style={{ color: "#003398" }}
          >
            autodiagnóstico
          </strong>
          .
        </Typography>
      </Grid>
      <Grid item xs={12} align="center">
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{ marginTop: 40 }}
          onClick={() => setAutodiagnostico(true)}
          startIcon={<BarChartIcon />}
        >
          Realizar autodiagnóstico
        </Button>
      </Grid>
    </Grid>
  );
}
