/* eslint-disable max-len */
import React from "react";
import {
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import img_1 from "../../../resources/icons/cms.png";
import img_2 from "../../../resources/icons/sensor.png";
import img_3 from "../../../resources/icons/buscar.png";
import img_4 from "../../../resources/icons/codigo.png";
import NetflixSlider from "../../../components/NetflixSlider/NetflixSlider";

const images = [
  {
    id: 1,
    image: "/images/dashboard.png",
    imageBg: "/images/dashboard.png",
    title: "DASHBOARD",
    description:
      "Mantén actualizados a tus clientes mediante su plataforma personal en la que podrán encontrar todo lo respectivo a sus retiros",
  },
  {
    id: 2,
    image: "/images/retiros.png",
    imageBg: "/images/retiros.png",
    title: "RETIROS",
    description:
      "Permite que los clientes sepan el estado de sus retiros y hasta que puedan solicitarlos libremente.",
  },
  {
    id: 3,
    image: "/images/sensores.png",
    imageBg: "/images/sensores.png",
    title: "SENSORES",
    description:
      "Administra los puntos limpios mediante sensores, obteniendo peiódicamente su estado de llenado.",
  },
  {
    id: 5,
    image: "/images/reportes.png",
    imageBg: "/images/reportes.png",
    title: "REPORTES",
    description:
      "Genera reportes mensuales en formato digital para tus clientes.",
  },
  {
    id: 6,
    image: "/images/reporte2.png",
    imageBg: "/images/reporte2.png",
    title: "REPORTE",
    description:
      "Genera reportes mensuales en formato digital para tus clientes.",
  },
];

const useStyles = makeStyles((theme) => ({
  title: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },
  subtitles: {
    margin: `0px 0px ${theme.spacing(4)}px`,
    textTransform: "uppercase",
    color: "#003398",
  },
  body: {
    //color: "#003398",
  },
  itemTitle: {
    textTransform: "uppercase",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    color: "#003398",
  },
  items: {
    marginLeft: "40px",
    marginRight: "40px",
  },
  img: {
    height: 90,
  },
  imgArrow: {
    height: 50,
    marginTop: 30,
    opacity: 0.6,
  },
  imgContainer: {
    margin: `${theme.spacing(2)}px 0px ${theme.spacing(2)}px`,
    width: "100%",
    height: 90,
    display: "flex",
    justifyContent: "center",
  },
}));

export default function Plataforma() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid container direction="row" justify="center" spacing={4}>
      <Grid item xs={12}>
        <Grid container justify="center">
          <Grid item xs={11} sm={10} md={8} lg={8}>
            <Typography
              variant={"h4"}
              align={"center"}
              className={classes.title}
            >
              SISTEMA DE GESTIÓN <strong>PLATAFORMA Y SENSORES</strong>
            </Typography>
            <br />
            <Typography
              variant={"h6"}
              align="center"
              className={classes.subtitle}
            >
              Si posees operaciones asociadas a la gestión de residuos,
              reciclaje o transporte de materiales, gestión de puntos verdes,
              este apartado es para ti.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={10}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={10} sm={3}>
            <div className={classes.imgContainer}>
              <img src={img_1} alt="admin" className={classes.img} />
            </div>
            <Typography
              className={classes.itemTitle}
              variant={"h5"}
              align="center"
            >
              {"ADMINISTRADOR"}
            </Typography>
            <Typography align="center" className={classes.body} variant={"h6"}>
              Administra tus procesos en una sola plataforma centralizada, desde
              los retiros hasta la reportabilidad
            </Typography>
          </Grid>
          <Grid item xs={10} sm={1}>
            <div className={classes.imgContainer}>
              <img
                src={img_4}
                alt="Colaboración"
                className={classes.imgArrow}
              />
            </div>
          </Grid>
          <Grid item xs={10} sm={3}>
            <div className={classes.imgContainer}>
              <img src={img_2} alt="sensores" className={classes.img} />
            </div>
            <Typography
              align="center"
              className={classes.itemTitle}
              variant={"h5"}
            >
              {"SENSORES"}
            </Typography>
            <Typography align="center" className={classes.body} variant={"h6"}>
              Instala sensores de medición de llenado en tus puntos limpios para
              optimizar tu logística y evitar colapsos
            </Typography>
          </Grid>
          <Grid item xs={10} sm={1}>
            <div className={classes.imgContainer}>
              <img
                src={img_4}
                alt="Colaboración"
                className={classes.imgArrow}
              />
            </div>
          </Grid>
          <Grid item xs={10} sm={3}>
            <div className={classes.imgContainer}>
              <img src={img_3} alt="control" className={classes.img} />
            </div>
            <Typography
              align="center"
              className={classes.itemTitle}
              variant={"h5"}
            >
              {"CONTROL"}
            </Typography>

            <Typography align="center" className={classes.body} variant={"h6"}>
              Obten reportes, eco equivalencias y trazabilidad del material
              reciclado en tu plataforma
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={11}>
        <br />
        <Typography variant={"h6"} align="justify">
          En Acción Circular tenemos un software especialmente diseñado para la{" "}
          <strong style={{ color: "#003398" }}>
            gestión de empresas de reciclaje
          </strong>
          . Abarcamos desde la creación de un retiro hasta la entrega de su
          información, permitiéndote{" "}
          <strong style={{ color: "#003398" }}>crear</strong> rutas de
          recolección <strong style={{ color: "#003398" }}>optimizadas</strong>{" "}
          evitando el sobreuso de tus vehículos y{" "}
          <strong style={{ color: "#003398" }}>disminuyendo</strong> tu impacto
          ambiental. También incluimos{" "}
          <strong style={{ color: "#003398" }}>sensores IOT</strong> para saber
          el estado de llenado de tus contenedores, permitiéndote así saber con{" "}
          <strong style={{ color: "#003398" }}>exactitud</strong> cuando y donde
          realizar un retiro.
        </Typography>
        <Typography variant={"h6"} align="justify">
          Una vez finalizado el ciclo de reciclaje, entrega{" "}
          <strong style={{ color: "#003398" }}>reportes de alta calidad</strong>{" "}
          a tus clientes para transparentar tu trabajo.
        </Typography>
        <br />
        <Typography variant={"h6"} align="justify">
          Dependiendo del plan que contrates, podrás entregar credenciales a tus
          clientes, de manera que ellos también tengan acceso a la plataforma y
          poder ver en tiempo real su reciclaje, cada vez que lo necesiten.
        </Typography>
      </Grid>
      {!isMobile && (
        <Grid item xs={12}>
          <br />
          <Grid container justify="center">
            <Grid item xs={12} sm={10} md={8} lg={6}>
              <Typography
                variant={"h4"}
                component={"h3"}
                align={"center"}
                className={classes.title}
              >
                Conoce la <strong>Plataforma</strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <NetflixSlider>
                {images.map((image) => (
                  <NetflixSlider.Item
                    movie={image}
                    key={"foto_netflix_plataforma_" + image.id}
                  />
                ))}
              </NetflixSlider>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
