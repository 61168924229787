import React from "react";
import {
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import Jimp from 'jimp';

export function TextFieldComponent({ answers, setAnswers, valueName }) {
  return (
    <TextField
      fullWidth
      variant="outlined"
      //value={answers[valueName]}
      key={valueName}
      onChange={(e) =>
        setAnswers({
          ...answers,
          [valueName]: e.target.value,
        })
      }
    />
  );
}

export function InputComponent({ answers, setAnswers, valueName }) {
  const handleSetImages = (event) => {

    Array.from(event.target.files).forEach((file) => {
      setTimeout(() => {
        Jimp.read(URL.createObjectURL(file))
          .then((photo) => {
            photo
              .resize(1062, 1280)
              .contain(800, 800)
              .quality(100)
              .getBase64Async(Jimp.MIME_JPEG)
              .then((result) => {
                setAnswers({ ...answers, [valueName]: result });
              })
              .catch((err) => console.error(err));
          })
          .catch((err) => {
            console.error(err);
          });
      }, 1000);
    });
  };

  const deleteImage = () => {
    setAnswers({ ...answers, [valueName]: null });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} align="center">
        {answers[valueName] && (
          <div>
            <Button
              onClick={() => deleteImage()}
              variant="contained"
              color="secondary"
              style={{ marginBottom: 20 }}
            >
              <DeleteIcon />
            </Button>

            <img
              src={answers[valueName]}
              style={{ width: "100%" }}
              alt="foto de contenedores"
            />
          </div>
        )}
      </Grid>
      <Grid item xs={12} align="center">
        <input
          type="file"
          accept="image/jpeg,image/jpg"
          id="raised-button-file-2"
          style={{ display: "none", width: "100%" }}
          onChange={(event) => handleSetImages(event)}
        />
        <label htmlFor="raised-button-file-2" style={{ width: "100%" }}>
          <Button
            variant="outlined"
            color="primary"
            style={{
              marginTop: 8,
              height: 40,
            }}
            fullWidth
            startIcon={<CloudUploadOutlinedIcon />}
            component="span"
          >
            Subir foto de mis contenedores
          </Button>
        </label>
      </Grid>
    </Grid>
  );
}

export function CheckboxComponent({ 
  answers,
  setAnswers,
  setShowText,
  valueName,
  options,
  textOthr,
  setTextOthr,
  showText,
   }) {
  return (
	
    <Grid container spacing={2}>
      {Object.values(options).map((option, i) => (
        <Grid item key={"checkbox_" + i} xs={6} sm={4} align="left">
			
			{
			 option.value=="Otro" &&  answers[valueName][option.value]? setShowText(true): setShowText(false)
			}
          <FormControlLabel		  
            control={
				
              <Checkbox
                checked={answers[valueName][option.value]}
                color="primary"
                onChange={(e) =>{
					if (option.value=="Otro") {
						console.log(showText);
						{textOthr[valueName]= "" }
						if(showText){
							setShowText(false)
								
							
						}else{
							setShowText(true)	
						}
						
					  }
                  setAnswers({...answers, [valueName]: {...answers[valueName],[option.value]: e.target.checked,},})
                }}
                name={option.value}
              />
            }
            label={option.value}
          />
        </Grid>
      ))}
	  {showText  && (
      <Grid container spacing={2}>
        <TextField
              label="Otro"
              margin="dense"
              variant="filled"
              multiline
              fullWidth
              rows="3"
			  value={textOthr[valueName]}
			 // key={valueName}
              onChange={(ev) =>{
				  
                setTextOthr({ ...textOthr, [valueName]: ev.target.value })
              }
                
              }
            />
      </Grid>
      )}
    </Grid>
  );
}

export function RadioButtonComponent({
  answers,
  setAnswers,
  valueName,
  options,
  conditional,
  textOthr,
  setTextOthr,
  showText,
  setShowText,
}) {
  if (conditional) {
	  {
				if (answers[valueName]=="Otro" ) {
					setShowText(true)
				}else{
					setShowText(false)
				}
			}
    return (
      <Grid item xs={12} align="center">
      <Grid container spacing={2}>
        <Grid item align="left">
          <RadioGroup
            aria-label="quiz"
            name="quiz"
            value={answers[valueName]}
            onChange={(e) =>{
              console.log(answers[valueName])
              if (e.target.value=="Otro" && answers[valueName]=="Q_INSTALATIONS" || answers[valueName]=="Q_WORKERS" || answers[valueName]=="Q_RECYCLES_KILOS") {
                setShowText(true)
              }else{
				setShowText(false)
				{textOthr[valueName]= "" }	
			  }   
              setAnswers({ ...answers, [valueName]: e.target.value })
            }
              
            }
          >
            {Object.values(options).map((option, i) => (
              <FormControlLabel
                key={"radio_" + i}
                value={option.value}
                control={<Radio color="primary" />}
                label={option.value}
              />
            ))}
          </RadioGroup>
        </Grid>
      </Grid>
      {showText && (
      <Grid container spacing={2}>
        <TextField
              label="Otro"
              margin="dense"
              variant="filled"
              multiline
              fullWidth
              rows="3"
			  value={textOthr[valueName]}
			 // key={valueName}
              onChange={(ev) =>{
				  
                setTextOthr({ ...textOthr, [valueName]: ev.target.value })
              }
                
              }
            />
      </Grid>
      )}
      </Grid>
    );
  } else {
    return (

	
	  <Grid item xs={12} align="center">
      <Grid container spacing={2}>
        <Grid item align="left">
          <RadioGroup
            aria-label="quiz"
            name="quiz"
            value={answers[valueName]}
            onChange={(e) =>{
			  if (e.target.value=="Otro" ) {
                setShowText(true)
              }else{
				setShowText(false)  
			  }
              setAnswers({ ...answers, [valueName]: e.target.value })
            }}
          >
            {options.map((option, i) => (
              <FormControlLabel
                key={"radio_" + i}
                value={option}
                control={<Radio color="primary" />}
                label={option}
              />
            ))}
          </RadioGroup>
        </Grid>
      </Grid>
	  </Grid>
    );
	
  }
}