import React from "react";

const AppContext = React.createContext();

export function AppContextProvider(props) {
  const [section, setSection] = React.useState("");
  const value = React.useMemo(() => {
    return {
      section,
      setSection,
    };
  }, [section, setSection]);
  return <AppContext.Provider value={value} {...props} />;
}

export function useAppContext() {
  const context = React.useContext(AppContext);
  if (!context) {
    throw Error("No suscrito");
  }
  return context;
}
