import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
} from "@material-ui/core";

import latas from "../../../resources/reciclamos/infografias/latas.jpg"
import papel_y_carton from "../../../resources/reciclamos/infografias/papel_y_carton.jpg"
import plasticos from "../../../resources/reciclamos/infografias/plasticos.jpg"
import tetra from "../../../resources/reciclamos/infografias/tetra.jpg"
import vidrios from "../../../resources/reciclamos/infografias/vidrios.jpg"

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing(4)}px 0px ${theme.spacing(4)}px`,
    maxWidth: "100%",
  },

  imgContainer: {
    // margin: `${theme.spacing(2)}px 0px ${theme.spacing(2)}px`,
    float: 'center',
    width: '70%',
    // height: '70%',
    objectFit: 'cover',
  },
}));


export default function Recibimos() {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={4}
      className={classes.container}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid item align="center">
        <Grid
          container
          spacing={4}
        >
          <Grid item md={12}>
            <img src={latas} className={classes.imgContainer} alt="latas" style={{ border: "1px solid #5C5F5F" }} />
          </Grid>
          <Grid item md={12}>
            <img src={papel_y_carton} className={classes.imgContainer} alt="papel_y_carton" style={{ border: "1px solid #354795" }} />
          </Grid>
          <Grid item md={12}>
            <img src={plasticos} className={classes.imgContainer} alt="plasticos" style={{ border: "1px solid #EC640D" }} />
          </Grid>
          <Grid item md={12}>
            <img src={tetra} className={classes.imgContainer} alt="tetra pak" style={{ border: "1px solid #8B7663" }} />
          </Grid>
          <Grid item md={12}>
            <img src={vidrios} className={classes.imgContainer} alt="vidrios" style={{ border: "1px solid #007D39" }} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

