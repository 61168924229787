import React from "react";

import { animateScroll as scroll } from "react-scroll";
import { Element } from "react-scroll";
import ReactGA from "react-ga";

import { useAppContext } from "../../context/AppContext";
import Header from "./Header";
import Publicaciones from "./Publicaciones";
import Leyrep from "../../components/Navigation/leyrep";

export default function Index() {
  const { setSection } = useAppContext();
  const [imagen, setImagen] = React.useState(true);
  React.useEffect(() => {
    scroll.scrollToTop();
    setSection("Noticias");
    ReactGA.pageview("/noticias");
  });
  return (
    <div>
	{imagen && (
		<Leyrep />
	  )}
      <Header />
      <Element name="publicaciones">
        <Publicaciones />
      </Element>
    </div>
  );
}
